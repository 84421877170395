import React from 'react';
import {
    makeStyles
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { NewFormControl } from './FormControl';

const useStyles = makeStyles(() => ({
    input: {
        fontSize: '14px',
        width: '100%',
        paddingRight: '0',
        '&> input': {
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '16px'
        }
    },
    date: {
        paddingLeft: '0'
    }
}));

export const DatePicker = (props) => {
    const classes = useStyles();
    const { label, name, errors, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;
    const id = `date-${name}`;
    return (
        <NewFormControl label={label} id={id} error={error}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    error={!!error}
                    name={name}
                    InputProps={{
                        className: classes.input
                    }}
                    {...rest}
                    disablePast
                    autoOk
                />
            </MuiPickersUtilsProvider>
        </NewFormControl>
    );
};

export const TimePicker = (props) => {
    const classes = useStyles();
    const { label, name, errors, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;
    const id = `time-${name}`;
    return (
        <NewFormControl label={label} id={id} error={error}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    variant="inline"
                    format="hh:mm aa"
                    inputVariant="outlined"
                    error={!!error}
                    name={name}
                    minutesStep={5}
                    InputProps={{
                        className: classes.input
                    }}
                    {...rest}
                    autoOk
                />
            </MuiPickersUtilsProvider>
        </NewFormControl>
    );
};

export default DatePicker;