export const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '14px',
            color: '#585858',
            padding: '10px 8px',
        },
            invalid: {
            color: '#9e2146',
        },
    }
};