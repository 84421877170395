import React, { createContext, useContext, useEffect, useState } from 'react';
import { authContext } from '../contexts/authContext';
import api from '../helpers/api';

export const useAuth = () => {
    return useContext(authContext);
}

const accountContext = createContext();

export const ProvideAccount = ({ children }) => {
    const account = useProvideAccount();

    return (
        <accountContext.Provider value={account}>{children}</accountContext.Provider>
    );
};

function useProvideAccount() {
    const { currentUser } = useAuth();
    const [currentAccount, setAccount] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        if (currentUser) {
            api('/api/accounts/getFromToken')
                .then(account => {
                    if (account) {
                        setAccount(account);
                    }
                })
                .catch(setError)
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setAccount(null);

    }, [currentUser]);

    return {
        accountLoading: loading,
        accountError: error,
        currentAccount,
        setAccount
    };
}

export const useAccount = () => {
    return useContext(accountContext);
};