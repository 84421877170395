import { useEffect } from 'react';
import useLoadingValue from './loadingHook';
import api from '../helpers/api';

export default function useApi(url, {
    method,
    body,
    query
} = {
    method: 'GET',
    body: null,
    query: {}
}) {
    const {
        setError,
        setValue,
        error,
        loading,
        value
    } = useLoadingValue();

    useEffect(() => {
        api(url, {
            method,
            body,
            query,
            setValue,
            setError
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, method]);
    
    return {
        error,
        loading,
        value,
        setValue,
        setError
    };
}