export default [
    'Appellee',
    'Appellant',
    'Petitioner',
    'Respondent',
    'Plaintiff',
    'Defendant',
    'Pro Se',
    'Claimant',
    'Co-Plaintiff',
    'Co-Defendant',
    'Cross Claimant',
    'Court-Appointed Attorney',
    'Garnishee',
    'Interested Party',
    'Movant',
    'Witness',
    'Trustee',
    'Other'
];