import React, { useEffect, useState } from 'react';
import { app } from '../../config';
import Button from '../button';
import ErrorList from '../form/ErrorList';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/authHook';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { parseFirebaseError } from '../../helpers/errors';
import {
    emailValidationOptions,
    passwordValidationOptions
} from '../../helpers/validation';
import { Box, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import Logo from '../Logo';
import { renderTextField } from '../form/TextField';
import ResetPasswordModal from './ForgotPasswordModal';
import useFormSubmission from '../../hooks/formSubmissionHook';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.grey,
        height: '100%'
    },
    divider: {
        marginTop: theme.spacing(3)
    },
    bottom: {
        marginTop: theme.spacing(3)
    }
}));

export default function Login() {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const { returnUrl } = location.state || { returnUrl: { pathname: '/' }};
    const { login, currentUser } = useAuth();
    const { control, handleSubmit, errors, formState } = useForm({
        defaultValues: {
            email: '',
            password: ''
        }
    });
    const { error, setError } = useFormSubmission();
    const { isSubmitting } = formState;

    const onFormSubmit = ({ email, password }) =>
        login(email, password)
            .then(response => {
                if (response.user) {
                    history.replace(returnUrl);
                }
            })
            .catch(err => {
                console.error(err);
                setError(err);
            });

    useEffect(() => {
        if (currentUser) {
            history.replace('/');
        }
    });

    return (
        <div className={classes.root}>
            <Logo />
            <Typography align="center" variant="h2" gutterBottom>{app.appName}</Typography>
            <Container maxWidth="xs">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    {renderTextField({ control, errors })({
                        name: 'email',
                        type: 'email',
                        placeholder: 'Email',
                        autocomplete: 'email',
                        validate: emailValidationOptions,
                        withFormControl: true,
                        showLabel: false
                    })}
                    {renderTextField({ control, errors })({
                        name: 'password',
                        type: 'password',
                        placeholder: 'Password',
                        autocomplete: 'new-password',
                        validate: passwordValidationOptions,
                        withFormControl: true,
                        showLabel: false
                    })}
                    <Box mb={2} display="flex" justifyContent="flex-end">
                        <Button color="primary" variant="text" onClick={() => setForgotPasswordModal(true)}>Forgot your password?</Button>
                    </Box>
                    {error && <ErrorList errors={[parseFirebaseError(error)]} />}
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        centered
                    >
                        Sign in
                    </Button>
                </form>
                <Divider className={classes.divider} />
            </Container>
            <div className={classes.bottom}>
                <Typography variant="body2" color="textSecondary">
                    Don't have an account? <Typography variant="body2" color="primary" component={Link} to="/signup">Create account</Typography>
                </Typography>
            </div>
            {forgotPasswordModal && <ResetPasswordModal onClose={() => setForgotPasswordModal(false)} />}
        </div>
    );
}