import React, { useEffect, useState } from 'react';
import useApi from '../hooks/apiHook';
import Information from '../components/hearings/Information';
import Review from '../components/hearings/Review';
import Payment from '../components/hearings/Payment';
import Confirmation from '../components/hearings/Confirmation';
import api from '../helpers/api';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAccount, useAuth } from '../hooks/authHook';
import { loadStripe } from '@stripe/stripe-js';
import { app } from '../config';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Breadcrumbs, Container, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FormStepper from '../components/hearings/FormStepper';
import getUTCDateAndTimezone from '../helpers/datetime';
import useFormSubmission from '../hooks/formSubmissionHook';
import { parseFirebaseError } from '../helpers/errors';
import { InfoNotification } from '../helpers/notifications';
import Logger from '../helpers/logger';
import {getEnvSpecificEmailFromUsernameEmail} from "../helpers/email";

const STEPS_ENUM = {
    'Information': 0,
    'Review': 1,
    'Payment': 2,
    'Confirmation': 3
};

const logger = new Logger('hearingRequest');

export default function SchedulePage() {
    const { register, currentUser } = useAuth();
    const { currentAccount, accountLoading, setAccount } = useAccount();
    const history = useHistory();
    const { hearingId } = useParams();
    const [activeStep, setActiveStep] = useState();
    const [formData, setFormData] = useState();
    const formSubmissionState = useFormSubmission();
    const [hearing, setHearing] = useState(null);
    const [paymentSecret, setPaymentSecret] = useState();
    const { value: states } = useApi('/api/states');
    const [judges, setJudges] = useState([]);
    const onHearingRequestFormSubmit = (data) => {
        console.info(data);
        const { hearingDate, hearingTime, ...rest } = data;
        hearingDate.setHours(hearingTime.getHours(), hearingTime.getMinutes(), 0, 0);
        const formattedData = {
            scheduledDate: hearingDate
        };

        // There has to be a better way to build this array but for now, anyway, this works:
        const participantsAppearing = [];
        for (let i = 0; i < 9999; i++) {
            if (data[`participantsAppearing${i}name`]) {
                // if that field exists, then the other two (email, phone) also do, so:
                participantsAppearing.push({
                    name: data[`participantsAppearing${i}name`],
                    email: data[`participantsAppearing${i}email`],
                    phone: data[`participantsAppearing${i}phone`]
                });
            } else {
                break; // get outta here since we're out of rows; could keep iterating but waste of time
            }
        }

        setFormData({participantsAppearing, ...rest, ...formattedData });
        setActiveStep(STEPS_ENUM.Review);
    };
    const onReviewFormSubmit = () => {
        if (formSubmissionState.loading) {
            return;
        }

        let userSignupPromise;
        if (currentUser && currentAccount) {
            userSignupPromise = () => Promise.resolve(currentAccount);
        } else if (formData.createAccount) {
            const {
                accountName,
                accountEmail,
                accountPassword,
                firmName
            } = formData;

            userSignupPromise = () => register(accountEmail, accountPassword)
                .then(({ user }) => {
                    if (user) {
                        const [firstName, lastName] = accountName.split(' ');
                        return api('/api/accounts/createFromFirebaseUser', {
                            method: 'POST',
                            body: {
                                firebaseUID: user.uid,
                                emailAddress: accountEmail,
                                stripeEmailAddress: getEnvSpecificEmailFromUsernameEmail(accountEmail),
                                firstName,
                                lastName,
                                firmName
                            }
                        }).then(async (account) => {
                            await user.updateProfile({
                                displayName: firstName
                            });
                            setAccount(account);
                            return account;
                        });
                    }

                    // Response should throw an error so we shouldn't get here
                    throw new Error('There was an error in creating your account');
                })
                .catch(error => {
                    logger.error(error);
                    // Cast to firebase error
                    throw new Error(parseFirebaseError(error));
                });
        } else {
            const {
                accountName,
                accountEmail,
                firmName
            } = formData;
            const [firstName, lastName] = accountName.split(' ');

            userSignupPromise = () => api('/api/accounts/createFromFirebaseUser', {
                method: 'POST',
                body: {
                    emailAddress: accountEmail,
                    stripeEmailAddress: getEnvSpecificEmailFromUsernameEmail(accountEmail),
                    firstName,
                    lastName,
                    firmName
                }
            }).then(account => {
                setAccount(account);
                return account;
            }).catch(error => {
                logger.error(error);
                formSubmissionState.setError(error);
            });
        }

        return userSignupPromise().then(account => {
            if (account) {
                const { courtReporterRequested, aiTranscriptRequested, audioRecordingRequested, scheduledDate, participantsAppearing, ...fields } = formData;
                const fieldsToSubmit = {
                    ...fields,
                    accountId: account.id,
                    scheduledDate: getUTCDateAndTimezone(scheduledDate),
                    participantAppearing: JSON.stringify(participantsAppearing)
                };
                if (courtReporterRequested === 'true') {
                    fieldsToSubmit.courtReporterRequested = true;
                }
                if (aiTranscriptRequested === 'true') {
                    fieldsToSubmit.aiTranscriptRequested = true;
                }
                if (audioRecordingRequested === 'true') {
                    fieldsToSubmit.audioRecordingRequested = true;
                }
                return api('/api/hearings/createHearing', {
                    method: 'POST',
                    body: fieldsToSubmit
                }).then(response => {
                    setHearing(response);
                    history.push(`/hearings/schedule/${response.jobId}`);
                }).catch(error => {
                    logger.error(error);
                    formSubmissionState.setError(error);
                });
            }

            // Response should throw an error so we shouldn't get here
            throw new Error('There was an error in creating your account');
        }).catch(error => {
            logger.error(error);
            formSubmissionState.setError(error);
        });
    };
    const onPaymentSuccess = (payment) => {
        console.info(`about to call /api/hearings/completeHearing...`);
        return api('/api/hearings/completeHearing', {
            method: 'POST',
            body: {
                jobId: hearing.jobId,
                paymentId: payment.id,
                amount: payment.amount,
                accountId: currentAccount ? currentAccount.id : null
            }
        }).then(response => {
            setHearing(response);
            setActiveStep(STEPS_ENUM.Confirmation);
        }).catch(error => {
            logger.error(error);
            formSubmissionState.setError(error);
        });
    };

    useEffect(() => {
        const fetchHearingData = async () => {
            let hearingRequest;
            if (!hearing) {
                hearingRequest = await api(`/api/hearings/getById/${hearingId}`)
                setHearing(hearingRequest);
            } else {
                hearingRequest = hearing;
            }

            if (!accountLoading) {
                if (currentAccount && currentAccount.id === hearingRequest.accountId) {
                    if (hearingRequest.status === 'Complete') {
                        setActiveStep(STEPS_ENUM.Confirmation);
                    } else if (hearingRequest.status === 'Incomplete') {
                        setActiveStep(STEPS_ENUM.Payment);
                    } else {
                        setActiveStep(STEPS_ENUM.Information);
                    }
                } else {
                    history.replace('/hearings/schedule');
                }
            }
        };

        if (hearingId) {
            fetchHearingData();
        } else if(!activeStep) {
            setActiveStep(STEPS_ENUM.Information);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [hearingId, accountLoading, currentAccount, activeStep]);

    useEffect(() => {
        if (activeStep === STEPS_ENUM.Payment && (hearing && hearing.judge.hearingFee > 0) && !paymentSecret) {
            api('/api/payments/createPayment', {
                method: 'POST',
                body: {
                    jobId: hearing.jobId,
                    stripeEmailAddress: getEnvSpecificEmailFromUsernameEmail(currentAccount.emailAddress),
                }
            })
            .then(paymentRequest => setPaymentSecret(paymentRequest.clientSecret))
            .catch(error => formSubmissionState.setError(error));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, hearing, paymentSecret]);

    const renderPayment = () => {
        const stripe = loadStripe(app.stripePublishableKey);
        return (
            <Elements stripe={stripe}>
                <Payment
                    hearingFee={hearing.judge.hearingFee}
                    paymentSecret={paymentSecret}
                    onPaymentComplete={onPaymentSuccess}
                    formSubmissionState={formSubmissionState}
                />
            </Elements>
        );
    };

    return (
        <Container maxWidth="lg" disableGutters>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Typography component={Link} color="primary" to="/hearings">Hearings</Typography>
                <Typography color="textPrimary">Schedule</Typography>
            </Breadcrumbs>
            <Box mt={2} mb={1} display="flex" justifyContent="space-between" flexWrap="wrap">
                <div>
                    <Typography color="primary" variant="h4">{activeStep === STEPS_ENUM.Confirmation ? 'Thank you!' : 'Schedule Virtual Hearing'}</Typography>
                    {(currentAccount && activeStep === STEPS_ENUM.Payment) && (
                        <InfoNotification>Your hearing is now in <strong>Draft</strong> status.</InfoNotification>
                    )}
                </div>
                <FormStepper activeStep={activeStep} steps={STEPS_ENUM} />
            </Box>
            {activeStep === STEPS_ENUM.Information && (
                <Information
                    states={states}
                    judges={judges}
                    setJudges={setJudges}
                    onFormSubmit={onHearingRequestFormSubmit}
                    formData={formData}
                />
            )}
            {activeStep === STEPS_ENUM.Review && (
                <Review
                    state={states.find(state => state.id === Number(formData.stateId))}
                    judge={judges.find(judge => judge.id === Number(formData.judgeId))}
                    formData={formData}
                    formSubmissionState={formSubmissionState}
                    onFormSubmit={onReviewFormSubmit}
                    onEditClick={() => {
                        setActiveStep(STEPS_ENUM.Information);
                        formSubmissionState.setError(null);
                    }}
                />
            )}
            {(activeStep === STEPS_ENUM.Payment && hearing) && renderPayment()}
            {activeStep === STEPS_ENUM.Confirmation && (
                <Confirmation hearing={hearing} />
            )}
        </Container>
    );
}