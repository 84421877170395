import React, { useEffect } from 'react';
import { app } from '../../config';
import Button from '../button';
import ErrorList from '../form/ErrorList';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/authHook';
import { Link, useHistory } from 'react-router-dom';
import {
    emailValidationOptions
} from '../../helpers/validation';
import api from '../../helpers/api';
import { Container, Divider, makeStyles, Typography } from '@material-ui/core';
import Logo from '../Logo';
import { renderTextField } from '../form/TextField';
import useFormSubmission from '../../hooks/formSubmissionHook';
import { SuccessNotification } from '../../helpers/notifications';
import {getEnvSpecificEmailFromUsernameEmail} from '../../helpers/email';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.grey,
        height: '100%'
    },
    divider: {
        marginTop: theme.spacing(3)
    },
    bottom: {
        marginTop: theme.spacing(3)
    }
}));

export default function VerifyEmail() {
    const classes = useStyles();
    const history = useHistory();
    const { logout, currentUser } = useAuth();
    const { control, handleSubmit, errors, formState } = useForm({
        defaultValues: {
            email: ''
        }
    });
    const { setError, setSuccess, ...formSubmissionState } = useFormSubmission();
    const { isSubmitting } = formState;

    const onFormSubmit = ({ email }) => {
        const firebaseEmailAddress = getEnvSpecificEmailFromUsernameEmail(email);
        return api('/api/accounts/verifyAccount', {
            method: 'POST',
            body: {
                emailAddress: email,
                firebaseEmailAddress // in propduction, same as emailAddress; in non-prod, different
            }
        })
        .then(() => {
            setSuccess('Account verification email was successfully sent.')
        })
        .catch(error => {
            console.error(error);
            setError(error);
            logout();
        });
    };

    useEffect(() => {
        if (currentUser) {
            history.replace('/');
        }
    });

    return (
        <div className={classes.root}>
            <Logo />
            <Typography align="center" variant="h2" gutterBottom>{app.appName}</Typography>
            <Container maxWidth="xs">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    {renderTextField({ control, errors })({
                        name: 'email',
                        type: 'email',
                        placeholder: 'Email',
                        autocomplete: 'email',
                        validate: emailValidationOptions,
                        withFormControl: true,
                        showLabel: false
                    })}
                    {formSubmissionState.error && <ErrorList errors={[formSubmissionState.error.message]} />}
                    {formSubmissionState.success && <SuccessNotification message={formSubmissionState.success} />}
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        centered
                    >
                        Verify Account
                    </Button>
                </form>
                <Divider className={classes.divider} />
            </Container>
            <div className={classes.bottom}>
                <Typography variant="body2" color="textSecondary">
                    Already have an account? <Typography variant="body2" color="primary" component={Link} to="/signin">Sign in</Typography>
                </Typography>
            </div>
        </div>
    );
}