import React from 'react';
import { theme } from '../../config';
import {
    makeStyles,
    withStyles,
    FormLabel,
    FormControlLabel,
    RadioGroup as MuiRadioGroup,
    Radio as MuiRadio } from '@material-ui/core';
import { NewFormControl } from './FormControl';

export const Radio = withStyles({
    root: {
        color: theme.colors.appPrimaryColor,
        '&$checked': {
            color: theme.colors.appPrimaryColor
        },
    },
    checked: {},
    })((props) => <MuiRadio color="default" {...props} />);

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '14px',
        color: theme.palette.text.primary,
        fontWeight: 600
    }
}));

export const RadioGroup = props => {
    const classes = useStyles();
    const { label, name, errors, value, onChange, options, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;
    const id = `radio-${name}`;
    return (
        <NewFormControl id={id} error={error}>
            <FormLabel classes={{ root: classes.label }}>{label}</FormLabel>
            <MuiRadioGroup name={name} value={value} onChange={onChange} {...rest} row>
                {options.map(option => (
                    <FormControlLabel key={option.value} control={<MuiRadio color="primary" />} value={option.value} label={option.label} />
                ))}
            </MuiRadioGroup>
        </NewFormControl>
    );
};

export default Radio;