import React, { useRef } from 'react';
import Button from '../button';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Typography, TextField, Divider, Box } from '@material-ui/core';
import ServicesList from '../services';
import HearingSummary from './HearingSummary';
import { ReactComponent as CourtReporterIcon } from '../icons/court_reporter-icon.svg';

const useStyles = makeStyles(theme => ({
    summary: {
        marginBottom: theme.spacing(5)
    },
    content: {
        marginTop: theme.spacing(1)
    },
    greyContainer: {
        backgroundColor: theme.palette.background.grey,
        borderRadius: 4,
        padding: '14px 20px',
        marginBottom: theme.spacing(3)
    },
    copyableField: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        'button': {
            marginLeft: theme.spacing(1)
        }
    },
    copyButton: {
        marginLeft: theme.spacing(1)
    },
    copyInput: {
        width: '100%',
        fontSize: 14,
        color: '#3455DB',
        backgroundColor: '#FFFFFF',
        height: 40
    },
    dividerGutter: {
        marginBottom: theme.spacing(3)
    }
}));

const VirtualMeetingLink = ({ virtualHearingLink }) => {
    const classes = useStyles();
    const copyableInput = useRef();

    const copyToClipboard = (e) => {
        copyableInput.current.select();
        document.execCommand('copy');
        e.target.focus();
    };

    return (
        <div className={classes.summary}>
            <div className={classes.greyContainer}>
                <Typography variant="h6" gutterBottom>Meeting link</Typography>
                <div className={classes.copyableField}>
                    <TextField
                        type="text"
                        variant="outlined"
                        className={classes.copyInput}
                        value={virtualHearingLink}
                        InputProps={{ className: classes.copyInput }}
                        readOnly
                        inputRef={copyableInput}
                    />
                    {document.queryCommandSupported('copy') && (
                        <Button className={classes.copyButton} variant="contained" color="secondary" size="small" onClick={copyToClipboard}>Copy Link</Button>
                    )}
                </div>
                <Typography
                    variant="body2"
                >
                    Don't have Zoom? <Typography
                        component="a"
                        variant="body2"
                        color="primary"
                        href="https://zoom.us/download"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Download here
                    </Typography>
                </Typography>
            </div>
            <Typography color="textSecondary" variant="body2" gutterBottom>You will receive a <strong>confirmation email</strong> soon with appearance detail.</Typography>
            <Typography color="textSecondary" variant="body2" gutterBottom>You can find your cases list in <Typography color="primary" variant="body2" component={Link} to="/hearings">Hearings</Typography>, and review your purchase in <Typography color="primary" variant="body2" component={Link} to="/payments">Payments</Typography>.</Typography>
        </div>
    );
};

export default function Confirmation(props) {
    const classes = useStyles();
    const { hearing } = props;
    const handleReporterRequestClick = () => {
        window.location = `mailto:scheduling@courtscribes.com?subject=${window.encodeURIComponent('Request a Court Reporter')}`;
    };

    return (
        <>
            <Box mb={2}>
                <Typography color="primary" variant="h4" gutterBottom>Below is your Virtual Hearing Link:</Typography>
            </Box>
            <Grid container spacing={4} className={classes.content}>
                <Grid item sm={7}>
                    <VirtualMeetingLink virtualHearingLink={hearing.zoomMeetingUrl} />
                    <Typography variant="h6" gutterBottom>Brief Hearing Summary</Typography>
                    <Divider className={classes.dividerGutter} />
                    <HearingSummary
                        jobId={hearing.jobId}
                        scheduledDate={hearing.scheduledDate}
                        judgeName={hearing.judge.displayName}
                        courtReporterRequested={hearing.courtReporterRequested}
                        aiTranscriptRequested={hearing.courtReporterRequested}


                    />
                </Grid>
                <Grid item sm={5}>
                    {hearing.courtReporterRequested ?
                        <>
                            <Typography color="primary" variant="h6">Need any additional services?</Typography>
                            <ServicesList />
                        </>
                            :
                        <div className={classes.greyContainer}>
                            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                <CourtReporterIcon />
                                <Box mt={3} mb={3} textAlign="center">
                                    <Typography variant="h6" gutterBottom>
                                        Need a Court Reporter for this hearing?
                                    </Typography>
                                    <Button variant="contained" color="primary" onClick={handleReporterRequestClick} fullWidth>Request a Follow-Up</Button>
                                </Box>
                            </Box>
                        </div>
                    }
                </Grid>
            </Grid>
        </>
    );
}