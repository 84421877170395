import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Box, Typography } from '@material-ui/core';

export default function ErrorList({ errors }) {
    return (
        <Box mt={2} mb={2} component={Alert} severity="error">
            {errors.map((err, idx) => <Typography color="textPrimary" variant="body2" key={idx}>{err}</Typography>)}
        </Box>
    );
}