import { makeStyles } from '@material-ui/core';
import React from 'react';

const getDirection = (direction) => {
    switch(direction) {
        case 'up':
            return '-135deg';
        case 'left':
            return '135deg';
        case 'right':
            return '-45deg';
        case 'down':
        default:
            return '45deg';
    }
};

const useStyles = makeStyles(() => ({
    arrow: {
        display: 'inline-block',
        width: 9,
        height: 9,
        borderStyle: 'solid',
        borderWidth: '0 2px 2px 0',
        borderColor: props => props.color,
        transform: props => `rotate(${getDirection(props.direction)})`
    }
}));

export default function Arrow({ direction = 'down', color = 'black' }) {
    const classes = useStyles({
        direction,
        color
    });
    return <span className={classes.arrow} />
}
