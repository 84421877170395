import React, { useEffect, createContext } from 'react';
import firebase from 'firebase/app';
import * as firebaseConfig from '../config/firebase';
import 'firebase/auth';
import useLoadingValue from '../hooks/loadingHook';
import {getEnvSpecificEmailFromUsernameEmail} from '../helpers/email';

firebase.initializeApp(firebaseConfig);

export const authContext = createContext();

export const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();

    if (auth.loading) {
        return null;
    }

    return (
        <authContext.Provider value={auth}>{children}</authContext.Provider>
    );
};

function useProvideAuth() {
    const {
        error,
        loading,
        setError,
        setValue: setUser,
        value: currentUser
    } = useLoadingValue();

    const login = (email, password) => {
        const firebaseEmail = getEnvSpecificEmailFromUsernameEmail(email);
        return firebase
        .auth()
        .signInWithEmailAndPassword(firebaseEmail, password);
    };

    const register = (email, password) => {
        const firebaseEmail = getEnvSpecificEmailFromUsernameEmail(email);
        return firebase
        .auth()
        .createUserWithEmailAndPassword(firebaseEmail, password);
    };

    const logout = () => {
        return firebase
          .auth()
          .signOut()
          .then(() => {
              setUser(null);
          })
          .catch(setError);
      };
    
      const sendPasswordResetEmail = (email) => {
          const firebaseEmail = getEnvSpecificEmailFromUsernameEmail(email);
          console.info(`About to call sendPasswordResetEmail() for ${firebaseEmail}`);
        return firebase
          .auth()
          .sendPasswordResetEmail(firebaseEmail)
          .then(() => {
            return true;
          })
          .catch(setError);
      };
    
      const confirmPasswordReset = (code, password) => {
        return firebase
          .auth()
          .confirmPasswordReset(code, password)
          .then(() => {
            return true;
          })
          .catch(setError);
      };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          if (user) {
              setUser(user);
          } else {
              setUser(null);
          }
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return {
        login,
        register,
        logout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        loading,
        error,
        currentUser,
        setError,
        setUser
    }
}