import React from 'react';
import {
    makeStyles,
    Checkbox as MuiCheckbox,
    FormControlLabel
} from '@material-ui/core';
import { NewFormControl } from './FormControl';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '14px'
    }
}));

export const Checkbox = (props) => {
    const classes = useStyles();
    const { label, name, errors, value, onChange, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;
    const id = `checkbox-${name}`;
    return (
        <NewFormControl id={id} error={error}>
            <FormControlLabel
                label={label}
                classes={{
                    label: classes.label
                }}
                control={
                    <MuiCheckbox
                        color="primary"
                        name={name}
                        variant="outlined"
                        size="small"
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                        {...rest}
                    />    
                }
            />
        </NewFormControl>
    );
};

export default Checkbox;