import { useReducer } from 'react';

const defaultState = (defaultValue) => ({
    loading: defaultValue === undefined,
    value: defaultValue,
    error: undefined
});

const reducer = (state, action) => {
    switch (action.type) {
        case 'error':
            return {
                ...state,
                error: action.error,
                loading: false,
                value: undefined
            };
        case 'reset':
            return defaultState(action.defaultValue);
        case 'value':
            return {
                ...state,
                error: undefined,
                loading: false,
                value: action.value
            };
        default:
            return state;
    }
};

export default function useLoadingValue(getDefaultValue) {
    const defaultValue = getDefaultValue && getDefaultValue();
    const [state, dispatch] = useReducer(reducer, defaultState(defaultValue));
    const reset = (defaultValue) => {
        dispatch({ type: 'reset', defaultValue });
    };
    
    const setError = (error) => {
        dispatch({ type: 'error', error });
    };
    
    const setValue = (value) => {
        dispatch({ type: 'value', value });
    };

    return {
        error: state.error,
        loading: state.loading,
        reset,
        setError,
        setValue,
        value: state.value
    };
}