import * as themeConfig from './theme';
import featureConfig from './features';

export const app = {
    appName: 'CourtScribes Virtual Scheduler',
    appTagLine: 'Delivering More for Less',
    hearingTypes: ['Case Management Conference', 'Motion', 'Special Set Hearing', 'Discovery Hearing', 'Status Conference', 'Arbitration', 'Mediation', 'Trial', 'Other'],
    stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    homePageHeadline: 'Virtual Proceedings',
    homePageDescription: 'We provide you with virtual hearing teleconference information and distribute it to all parties.',


    // No longer used:
    /*homePageServices: [
        'Replace in-person courtroom appearances with virtual hearings',
        'Efficient use of professional and legal resources',
        'Reduce the cost of litigation thus saving your clients money',
        'Utilize technology to maximize productivity',
        'Eliminate travel time and expenses'
    ],*/
    homePageAdditionalServicesHeadline: 'Additional CourtScribes Services',
    homePageTestimonialCopy: 'CourtScribes provided on-demand technical and operator support during one of my trials that had to be held remotely through a video connection. CourtScribes coordinated all aspects of creating a virtual courtroom environment…. CourtScribes has the knowledge, expertise, tools and experience to handle any facet of the virtual trial experience.',
    homePageTestimonialAuthor: 'Adam Gutride',
    homePageTestimonialCompany: 'Gutride Safier LLP'
};

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3310';
export const features = featureConfig;
export const theme = themeConfig;

export default {
    apiUrl,
    features: featureConfig,
    ...theme,
    ...app
}