import React from 'react';
import Button from '../components/button';
import config from '../config';
import { Card, CardMedia, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as CheckmarkIcon} from '../components/icons/checkmark-icon.svg';
import { Link } from 'react-router-dom';
import ServicesList from '../components/services';

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: 0
    },
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(4),
        '&:last-of-type': {
            marginTop: theme.spacing(4)
        }
    },
    column: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    scheduleItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    listItemAvatar: {
        minWidth: '62px'
    },
    serviceAvatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.grey,
        border: '1px solid #E3E3E3',
        padding: 6
    },
    quoteContainer: {
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            backgroundColor: theme.palette.background.grey,
            borderRadius: '50%',
            padding: '9rem',
            top: -30,
            left: -40,
            zIndex: -1
        }
    },
    quote: {
        fontSize: 20,
        fontStyle: 'italic',
        position: 'relative',
        '&::before': {
            content: 'open-quote',
            display: 'block',
            position: 'absolute',
            color: theme.palette.primary.main,
            fontSize: '5rem',
            transform: 'translateY(-50%)'
        },
        '&::after': {
            content: 'close-quote',
            display: 'block',
            position: 'absolute',
            color: theme.palette.primary.main,
            fontSize: '5rem'
        }
    },
    quoteAttribution: {
        textAlign: 'right'
    }
}));

export default function Home() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container className={classes.container} maxWidth="lg" disableGutters>
            <Grid container className={classes.root}>
                <Grid item md={6} className={classes.column}>
                    <Typography variant="h4" color="primary" gutterBottom>{config.homePageHeadline}</Typography>
                    <Typography variant="body1">{config.homePageDescription}</Typography>
                    <Button style={{margin: `12px auto 0 auto`}} component={Link} variant="contained" to="/hearings/schedule" fullWidth={isMobile}>Schedule Now</Button>
                    <Divider style={{margin: `30px 0 0 0`}} />
                    <Grid style={{marginTop: `20px`}} item md={12} className={classes.column}>
                        <Typography variant="h6" color="primary" gutterBottom>{config.homePageAdditionalServicesHeadline}</Typography>
                        <ServicesList />
                    </Grid>
                </Grid>
                <Grid item md={6} className={classes.column}>
                    <Card elevation={0} square>
                        <CardMedia
                            component={'img'}
                            image="/static/homepage.png"
                        />
                    </Card>
                    <Grid item md={11} style={{marginTop: `50px`, marginLeft: `4px`}} className={classes.column}>
                        <div className={classes.quoteContainer}>
                            <Typography variant="h2" color="primary" className={classes.quote}>{config.homePageTestimonialCopy}</Typography>
                            <div className={classes.quoteAttribution}>
                                <Typography variant="body1" color="textPrimary">- {config.homePageTestimonialAuthor}</Typography>
                                <Typography variant="body1" color="textSecondary">{config.homePageTestimonialCompany}</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            {/*<Divider />*/}
                <Grid item md={1}></Grid>
            </Grid>


        </Container>
    );
}