import React from 'react';
import {
    List
} from '@material-ui/core';

export default function CreditCardList({
    loading, error, paymentMethods = [], children
}) {
    if (error) {
        return <p>There was an error retrieving saved payments.</p>;
    }

    if (loading) {
        return (
            <p>Loading...</p>
        );
    } else if (!paymentMethods.length) {
        return (
            <p>No payment methods saved yet.</p>
        );
    }

    return (
        <List disablePadding>
            {children}
        </List>
    );
}