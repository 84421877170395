export default class Logger {
    scope;
    env;

    constructor(scope) {
        this.scope = scope;
        this.env = process.env.NODE_ENV;
        this.logger = window.LE;
    }

    info = (message, context) => this.log('info', message, context);
    warn = (message, context) => this.log('warn', message, context);
    error = (message, context) => this.log('error', message, context);

    log(method = 'log', message, context) {
        const stream = console[method] || console.log;
        stream(`${method.toUpperCase()}: ${this.scope} ${message}`, context);
        if (this.logger) {
            this.logger[method](message, {...context, scope: this.scope });
        }
    }
}