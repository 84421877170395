const colors = {
    appPrimaryColor: '#2F4C75',
    appSecondaryColor: '#FFFFFF',
    appDark: '#000000',
    appLight: '#FFFFFF',
    appGreyDark: '#545454',
    appGreyLight: '#767676',
    sidebarBackgroundColor: '#F7F8FA',
    sidebarBorderColor: '#E3E3E3',
    error: '#f44336'
};

const typography = {
    headingFontFamily: "Libre Baskerville",
    bodyFontFamily: "Helvetica Neue"
};

const breakpoints = {
    medium: 968,
    large: 1200
};

export {
    colors,
    typography,
    breakpoints
}