import React from 'react';
import {
    makeStyles,
    Select as MuiSelect
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NewFormControl } from './FormControl';

const useStyles = makeStyles(() => ({
    input: {
        fontSize: '14px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        width: '100%'
    },
    select: {
        width: '100%',
        cursor: 'pointer'
    }
}));

export const Select = (props) => {
    const classes = useStyles();
    const { children, label, name, errors, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;
    const id = `select-${name}`;
    return (
        <NewFormControl label={label} id={id} error={error}>
            <MuiSelect
                error={!!error}
                labelId={id}
                variant="outlined"
                size="small"
                className={classes.select}
                SelectDisplayProps={{
                    className: classes.input
                }}
                IconComponent={ExpandMoreIcon}
                displayEmpty
                {...rest}
            >
                {children}
            </MuiSelect>
        </NewFormControl>
    );
};

export default Select;