import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { Box, Divider, Grid, Typography, Breadcrumbs, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';
import api from '../helpers/api';
import { format, isFuture, subHours } from 'date-fns';
import useFormSubmission from '../hooks/formSubmissionHook';
import ErrorList from '../components/form/ErrorList';
import Button from '../components/button';
import { parseUTCDateTime } from '../helpers/datetime';
import ParticipantsList from "../components/ParticipantsList";

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  status: {
    fontWeight: 600
  }
}));

export default function HearingDetail() {
  const classes = useStyles();
  const { params } = useRouteMatch();
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [hearing, setHearing] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const cancelHearingSubmissionState = useFormSubmission();

  const handleHearingCancel = () => api('/api/hearings/cancelHearing', {
      method: 'POST',
      body: {
        jobId: hearing.jobId
      }
    }).then(() => {
      setShowCancelModal(false);
      setShowCancelConfirmModal(true);
      setHearing(prevState => ({
        ...prevState,
        status: 'Cancelled'
      }));
      cancelHearingSubmissionState.setSuccess('Your hearing was successfully cancelled.')
    }).catch(error => cancelHearingSubmissionState.setError(error));

  useEffect(() => {
    const fetchHearing = async () => {
      setFetching(true);
      try {
        const hearings = await api(`/api/hearings/${params.id}`);
        setHearing(hearings);
        setError('');
      } catch (error) {
        setError(error.message);
      } finally {
        setFetching(false);
      }
    };
    fetchHearing();
  }, [setHearing, params]);

  if (fetching || !hearing) {
    return <Typography color="primary">Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  // This is a temp solution to know when null is returned because the api funtion returns the response object
  // when the backend returns null
  if (hearing && hearing.body) {
    return <Typography color="error">No data found for hearing with job id #{params.id}</Typography>;
  }

  // "Active" mainly denotes that the hearing won't start for at least another hour
  // Hearings shouldn't be cancellable only an hour up to it scheduled date
  const isHearingActive = hearing.status === 'Complete' && isFuture(subHours(new Date(hearing.scheduledDate), 1));
  const isHearingDraft = hearing.status === 'Incomplete' && isFuture(new Date(hearing.scheduledDate));
  const { local } = parseUTCDateTime(hearing.scheduledDate, hearing.scheduledDateTimezone);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Typography component={Link} color="primary" to="/hearings">
          Hearings
        </Typography>
        <Typography color="textPrimary">#{params.id}</Typography>
      </Breadcrumbs>
      <Box mt={2} mb={4}>
        <Typography color="primary" variant="h4">{hearing.caseName}</Typography>
        {hearing.status === 'Cancelled' &&
          <Typography variant="body2" color="error">This hearing has been cancelled.</Typography>
        }
      </Box>
      <Typography gutterBottom color="textPrimary" variant="h6">General information</Typography>
      <Divider />
      <Box pt={3} pb={8} maxWidth={400}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Status</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1" className={classes.status}>{isHearingDraft ? 'Draft' : new Date(hearing.scheduledDate) < new Date() ? 'Past' : 'Upcoming'}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Case Number</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">#{hearing.caseNumber}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Job Number</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">#{hearing.jobId}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Fee</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">${hearing.judge ? hearing.judge.hearingFee : 0}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography gutterBottom color="textPrimary" variant="h6">Case information</Typography>
      <Divider />
      <Box pt={3} pb={8} maxWidth={400}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Case Number</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">#{hearing.caseNumber}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Case Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">{hearing.caseName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Date</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">{format(local, 'MMMM d, yyyy')}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Time</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">{format(local, 'hh:mm a')}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">State</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">{hearing.state && hearing.state.name}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Judge</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">{hearing.judge && hearing.judge.displayName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Participant(s) Appearing</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom color="textPrimary" variant="body1">
              <ParticipantsList participantAppearing={hearing.participantAppearing} />
            </Typography>
          </Grid>
        </Grid>
        {
          hearing.partyRepresenting
              ? <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Typography color="textPrimary" variant="body1">Party Representing</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textPrimary" variant="body1">{hearing.partyRepresenting}</Typography>
                  </Grid>
                </Grid>
              : <></>
        }

        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">Type of Hearing</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="body1">{hearing.hearingType}</Typography>
          </Grid>
        </Grid>
      </Box>
      {isHearingDraft &&
        <Box pb={5} maxWidth={250}>
          <Button component={Link} to={`/hearings/schedule/${hearing.jobId}`} fullWidth size="large">Complete</Button>
        </Box>
      }
      {(isHearingActive) &&
        <>
          <Box pb={5} maxWidth={250}>
            <Button fullWidth variant="outlined" size="large" color="primary" onClick={() => setShowCancelModal(true)}>Cancel Hearing</Button>
          </Box>
          <Dialog open={showCancelModal} onClose={() => setShowCancelModal(false)}>
            <IconButton className={classes.closeButton} aria-label="Close" onClick={() => setShowCancelModal(false)}>
              <CloseIcon />
            </IconButton>
            <Box component={DialogContent} display="flex" flexDirection="column">
            <DialogTitle color="primary">
              Are you sure you want to cancel?
            </DialogTitle>
              <DialogContentText align="center">
                This action cannot be undone.
              </DialogContentText>
              {cancelHearingSubmissionState.error && <ErrorList errors={[cancelHearingSubmissionState.error.message]} />}
              <Button
                variant="contained"
                color="primary"
                onClick={cancelHearingSubmissionState.submitHandler(handleHearingCancel)}
                loading={cancelHearingSubmissionState.loading}
                disabled={cancelHearingSubmissionState.loading}
                centered
              >
                Cancel Hearing
              </Button>
            </Box>
          </Dialog>
        </>
      }
      {showCancelConfirmModal &&
        <Dialog open={showCancelConfirmModal} onClose={() => setShowCancelConfirmModal(false)}>
          <IconButton className={classes.closeButton} aria-label="Close" onClick={() => setShowCancelConfirmModal(false)}>
            <CloseIcon />
          </IconButton>
          <Box component={DialogContent} display="flex" flexDirection="column">
          <DialogTitle color="primary">
            Your hearing has been cancelled.
          </DialogTitle>
            <DialogContentText align="center">
              You will receive an email confirmation of this cancellation.
            </DialogContentText>
            <Button centered variant="contained" color="primary" onClick={() => history.push('/hearings')}>
              Return to Hearings
            </Button>
          </Box>
        </Dialog>
      }
    </>
  );
}