const rawNonprodEmailNameQualifier = (process.env.REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER || ``).trim();
const appEnv = process.env.REACT_APP_APP_ENV;

if (!appEnv) {
    throw new Error(`REACT_APP_APP_ENV not present; this error is fatal.`);
}

const isProduction = appEnv === `production`;

if (isProduction) {
    if (rawNonprodEmailNameQualifier) {
        console.warn(`REACT_APP_APP_ENV is "production" but REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER is defined; ignoring this value.`);
    }
} else {
    if (!rawNonprodEmailNameQualifier) {
        throw new Error(`REACT_APP_APP_ENV is "${appEnv}" but REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER is not defined; this error is fatal.`);
    }
    if (!(/^[a-z0-9]*$/.test(rawNonprodEmailNameQualifier))) {
        throw new Error(`REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER must contain alphanumeric, lowercase characters only; this error is fatal.`);
    }

    if (rawNonprodEmailNameQualifier.indexOf(`yourname`) > -1) {
        throw new Error(`REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER is ${process.env.REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER} - perhaps you didn't change its value in one of the .env files? This error is fatal.`);
    }
}

// Never change these values; Firebase has email addresses stored which contain them:
const qualifierPrefix = `+-----`;
const qualifierSuffix = `-----`;

// Note: we never use the following var in prod but we'll build it anyway:
const nonprodEmailNameQualifier = `${qualifierPrefix}${rawNonprodEmailNameQualifier}${qualifierSuffix}`;
/**
 * In production, returns `emailAddress` unchanged. In non-prod environments, passed something like xxx@yyy.zzz,
 * returns something like xxx+-----staging-----@yyy.zzz or xxx+-----sschneiderlocaldev-----@yyy.zzz.  We do this because we use
 * the same Firebase account for all environments; obviously if we have a xxx@yyy.zzz stored in both staging and
 * production, we can't have xxx@yyy.zzz twice in Firebase. So, for non-production environments, we add a "+" to
 * the username portion of the email.
 * @param emailAddress The user's email address which is their username for this app
 * @return In production, returns `emailAddress`. In other envs, returns something like xxx+-----sschneiderlocaldev-----@yyy.zzz.
 */
export const getEnvSpecificEmailFromUsernameEmail = (emailAddress) => {
    if (isProduction) {
        return emailAddress;
    }
    const [username, domain] = emailAddress.split(`@`);
    return `${username}${nonprodEmailNameQualifier}@${domain}`;
};


/**
 * Does the opposite of `getEnvSpecificEmailFromUsernameEmail()`.
 *
 * In production, returns `emailAddress` unchanged. In non-prod environments, in e.g. an environment where
 * REACT_APP_FIREBASE_NONPROD_EMAIL_NAME_QUALIFIER is "sschneiderlocaldev", passed something like xxx+-----sschneiderlocaldev-----@yyy.zzz,
 * returns something like xxx@yyy.zzz.  See JSDocs for `getEnvSpecificEmailFromUsernameEmail()` for more.
 * @param emailAddress An email originally returned by `getEnvSpecificEmailFromUsernameEmail()`
 * @return In production, returns `emailAddress`. In other envs, returns something like xxx+-----sschneiderlocaldev-----@yyy.zzz.
 */
export const getUsernameEmailFromEnvSpecificEmail = (emailAddress) => {
    if (isProduction) {
        return emailAddress;
    }
    const [username, domain] = emailAddress.split(`@`);
    const preQualifierUsername = (username.lastIndexOf(`+`) === -1 ? username : username.substring(username.lastIndexOf(`+`)));
    return `${preQualifierUsername}@${domain}`;
};
