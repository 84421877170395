import React, { createContext, useEffect, useState } from 'react';
import api from '../helpers/api';
import { useAuth } from '../hooks/authHook';

export const hearingContext = createContext();

export const ProvideHearings = ({ children }) => {
    const { currentUser } = useAuth();
    const [hearings, setHearings] = useState([]);

    useEffect(() => {
        const getHearings = async () => {
            try {
                const hearingsCount = await api('/api/hearings/byAccount');
                setHearings(hearingsCount);
            } catch(error) {
                console.error(error);
            }
        };

        if (currentUser) {
            getHearings();
        }
    }, [currentUser]);

    return (
        <hearingContext.Provider value={{ hearings, setHearings }}>{children}</hearingContext.Provider>
    );
};