import queryString from 'querystring';
import firebase from 'firebase/app';
import ApiError from './errors';

export default async function api(url, params = {
    method: 'GET',
    query: {}
}) {
    const {
        method,
        body,
        query,
        setValue,
        setError
    } = params;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });
    const fetchAttrs = {};
    if (body) {
        fetchAttrs.body = JSON.stringify(body);
    }

    const user = firebase.auth().currentUser;
    const token = user && await user.getIdTokenResult().then(result => result.token);

    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    try {
        const res = await window.fetch(`${url}?${queryString.stringify(query)}`, {
            method,
            headers,
            ...fetchAttrs
        });
        const json = await res.json().catch((e) => {
            throw new Error(`${url} - ${e.message}`);
        });

        if (res.status >= 400) {
            if (json.error) {
                if (res.status === 422) {
                    throw new ApiError(json.error, 422, (json.meta && json.meta.code));
                } else if (res.status < 500) {
                    throw new ApiError(json.error, res.status, (json.meta && json.meta.code));
                } else {
                    throw new ApiError(json.error, res.status, (json.meta && json.meta.code));
                }
            } else {
                throw new Error('Unknown error occurred')
            }
        }

        const data = json.data ? json.data : res;
        return setValue ? setValue(data) : data;
    } catch (error) {
        console.error(`${url}`, error);
        if (setError) {
            setError(error);
        } else {
            throw error;
        }
    }
}