import React from 'react';
import { NewFormControl as FormControl } from '../form/FormControl';
import Typography from '@material-ui/core/Typography';

export default function AccountReview(props) {
    const {
        accountName,
        firmName,
        accountEmail
    } = props;

    return (
        <>
            <FormControl label="Name" id="accountName" displayOnly>
                <Typography variant="body2">{accountName}</Typography>
            </FormControl>
            <FormControl label="Firm" id="firmName" displayOnly>
                <Typography variant="body2">{firmName}</Typography>
            </FormControl>
            <FormControl label="Email" id="accountEmail" displayOnly>
                <Typography variant="body2">{accountEmail}</Typography>
            </FormControl> 
        </>          
    );
}