import React from 'react';
import Button from './button';
import { app } from '../config';
import { useAccount, useAuth } from '../hooks/authHook';
import { useHistory, Link } from 'react-router-dom';
import Logo from './Logo';
import {
    Toolbar,
    Typography,
    Hidden,
    IconButton,
    makeStyles,
    Divider,
    Box
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as UserIcon } from './icons/user-icon.svg';
import useHearings from '../hooks/hearingsHook';

const useStyles = makeStyles(theme => ({
    toolbar: {
        justifyContent: 'space-between',
        paddingTop: 6,
        paddingBottom: 8
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    authLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minWidth: 150,
        fontSize: '1rem',
        color: theme.palette.text.secondary,
        textDecoration: 'none'
    },
    signInButton: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    signUpButton: {
        minWidth: 'auto',
        marginLeft: theme.spacing(2)
    },
    heading: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h6.fontSize,
        [theme.breakpoints.down('sm')]: {
            fontSize: '.75rem',
            textAlign: 'center'
        }
    },
    avatar: {
        paddingRight: theme.spacing(1)
    }
}));

const SignInLinks = () => {
    const classes = useStyles();
    return (
        <div className={classes.navContainer}>
            <Button component={Link} to="/signin" variant="text" color="primary" className={classes.signInButton}>Sign in</Button>
            <Button component={Link} to="/signup" variant="contained" color="primary" className={classes.signUpButton}>Sign up</Button>
        </div>
    );
};

const Profile = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.navContainer}>
            <Link to="/account" className={classes.authLink}>
                <UserIcon className={classes.avatar} />
                {`Welcome${props.user.displayName ? `, ${props.user.displayName}` : ''}!`}
            </Link>
            <Divider orientation="vertical" flexItem />
            <Box pl={2}>
                <Button size="small" variant="text" onClick={props.onLogoutClick}>Log out</Button>
            </Box>
        </div>
    );
};

export const AuthNav = ({
    currentUser,
    onLogoutClick
}) => currentUser ? <Profile user={currentUser} onLogoutClick={onLogoutClick} /> : <SignInLinks />;

export default function AppBar(props) {
    const classes = useStyles();
    const history = useHistory();
    const { currentUser, logout } = useAuth();
    const { setAccount } = useAccount();
    const { setHearings } = useHearings();
    const handleLogoutClick = () => {
        logout();
        setAccount(null);
        setHearings(0);
        history.replace('/');
    };
    return (
        <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
                <Logo width={60} height={60} isMobile />
            </Hidden>
            <Typography className={classes.heading} variant="h2">
                {app.appName}
            </Typography>
            <Hidden smDown>
                <AuthNav currentUser={currentUser} onLogoutClick={handleLogoutClick} />
            </Hidden>
            <Hidden mdUp>
                <IconButton
                    color="primary"
                    aria-label="open menu"
                    onClick={props.toggleMenu}
                >
                    <MenuIcon />
                </IconButton>
            </Hidden>
        </Toolbar>
    );
}