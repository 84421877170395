import React from 'react';
import { useAccount } from '../../hooks/authHook';
import { TextField, Checkbox } from '../form';
import AccountReview from './AccountReview';
import {
    fullNameValidationOptions,
    emailValidationOptions,
    passwordValidationOptions,
    existingAccountValidation
} from '../../helpers/validation';
import { Controller } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { NewFormControl } from '../form/FormControl';

export default function AccountForm(props) {
    const { currentAccount, accountLoading } = useAccount();
    const { control, errors, createAccount } = props;

    if (accountLoading) {
        return (
            <div>Loading...</div>
        );
    }

    if (currentAccount) {
        return (
            <AccountReview
                accountName={`${currentAccount.firstName} ${currentAccount.lastName}`}
                firmName={currentAccount.firmName}
                accountEmail={currentAccount.emailAddress}
            />
        );
    }

    return (
        <>
            <Controller
                control={control}
                name="accountName"
                rules={fullNameValidationOptions}
                render={props =>
                    <TextField
                        label="Name"
                        placeholder="Your Name"
                        autocomplete="name"
                        errors={errors}
                        {...props}
                    />
                }
            />
            <Controller
                control={control}
                name="firmName"
                render={props =>
                    <TextField
                        label="Firm"
                        placeholder="Firm Name"
                        autocomplete="organization"
                        errors={errors}
                        {...props}
                    />
                }
            />
            <Controller
                control={control}
                name="accountEmail"
                rules={{
                    ...emailValidationOptions,
                    validate: existingAccountValidation
                }}
                render={props =>
                    <TextField
                        label="Email"
                        placeholder="Email Address"
                        autocomplete="email"
                        errors={errors}
                        {...props}
                    />
                }
            />
            {createAccount &&
                <Controller
                    control={control}
                    name="accountPassword"
                    rules={passwordValidationOptions}
                    render={props =>
                        <TextField
                        type="password"
                            label="Password"
                            placeholder="Account password"
                            autocomplete="new-password"
                            errors={errors}
                            {...props}
                        />
                    }
                />
            }
            <Controller
                control={control}
                name="createAccount"
                render={props =>
                    <Checkbox
                        label="Save and Create Account"
                        {...props}
                    />
                }
            />
            <NewFormControl>
                <Typography variant="body2" style={{ fontWeight: 600 }}>New here?</Typography>
                <Typography variant="body2" color="textSecondary">
                    We recommend creating an account as you schedule for faster scheduling next time!
                </Typography>
            </NewFormControl>
        </>
    );
}