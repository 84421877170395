import React, { useEffect } from 'react';
import { app } from '../../config';
import Button from '../button';
import ErrorList from '../form/ErrorList';
import { useForm } from 'react-hook-form';
import { useAccount, useAuth } from '../../hooks/authHook';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { parseFirebaseError } from '../../helpers/errors';
import {
    fullNameValidationOptions,
    emailValidationOptions,
    passwordValidationOptions,
    existingAccountValidation
} from '../../helpers/validation';
import api from '../../helpers/api';
import { Container, Divider, makeStyles, Typography } from '@material-ui/core';
import Logo from '../Logo';
import { renderTextField } from '../form/TextField';
import useFormSubmission from '../../hooks/formSubmissionHook';
import {getEnvSpecificEmailFromUsernameEmail} from '../../helpers/email';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.grey,
        height: '100%'
    },
    divider: {
        marginTop: theme.spacing(3)
    },
    bottom: {
        marginTop: theme.spacing(3)
    }
}));

export default function Register() {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const { returnUrl } = location.state || { returnUrl: { pathname: '/' }};
    const { register: authRegister, logout, currentUser } = useAuth();
    const { setAccount } = useAccount();
    const { control, handleSubmit, errors, formState } = useForm({
        defaultValues: {
            fullName: '',
            email: '',
            password: ''
        }
    });
    const { setError, ...formSubmissionState } = useFormSubmission();
    const { isSubmitting } = formState;

    const onFormSubmit = ({ fullName, email, password }) => {
        const [firstName, lastName] = fullName.split(' ');
        
        return authRegister(email, password)
            .then(({ user }) => {
                if (user) {
                    return api('/api/accounts/createFromFirebaseUser', {
                        method: 'POST',
                        body: {
                            firebaseUID: user.uid,
                            emailAddress: email,
                            stripeEmailAddress: getEnvSpecificEmailFromUsernameEmail(email),
                            firstName,
                            lastName
                        }
                    })
                    .then(async (account) => {
                        await user.updateProfile({
                            displayName: account.firstName
                        });
                        setAccount(account);
                        history.replace(returnUrl);
                    })
                    .catch(err => {
                        console.error(err);
                        setError(err);
                        logout();
                    });
                }

                throw new Error('There was an error in creating your account');
            })
            .catch(setError);
    };

    useEffect(() => {
        if (currentUser) {
            history.replace('/');
        }
    });

    return (
        <div className={classes.root}>
            <Logo />
            <Typography align="center" variant="h2" gutterBottom>{app.appName}</Typography>
            <Container maxWidth="xs">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    {renderTextField({ control, errors })({
                        name: 'fullName',
                        type: 'text',
                        placeholder: 'Your name',
                        autocomplete: 'name',
                        validate: fullNameValidationOptions,
                        withFormControl: true,
                        showLabel: false
                    })}
                    {renderTextField({ control, errors })({
                        name: 'email',
                        type: 'email',
                        placeholder: 'Email',
                        autocomplete: 'email',
                        validate: {
                            ...emailValidationOptions,
                            validate: existingAccountValidation
                        },
                        withFormControl: true,
                        showLabel: false
                    })}
                    {renderTextField({ control, errors })({
                        name: 'password',
                        type: 'password',
                        placeholder: 'Password (6+ characters)',
                        autocomplete: 'new-password',
                        validate: passwordValidationOptions,
                        withFormControl: true,
                        showLabel: false
                    })}
                    {formSubmissionState.error && <ErrorList errors={[parseFirebaseError(formSubmissionState.error)]} />}
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        centered
                    >
                        Create Account
                    </Button>
                </form>
                <Divider className={classes.divider} />
            </Container>
            <div className={classes.bottom}>
                <Typography variant="body2" color="textSecondary">
                    Already have an account? <Typography variant="body2" color="primary" component={Link} to="/signin">Sign in</Typography>
                </Typography>
            </div>
        </div>
    );
}