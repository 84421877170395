import React from 'react';
import Button from '../button';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(4),
        '&:last-of-type': {
            marginTop: theme.spacing(4)
        }
    },
    column: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    scheduleItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    listItemAvatar: {
        minWidth: '62px'
    },
    serviceAvatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.grey,
        border: '1px solid #E3E3E3',
        padding: 6
    },
    quoteContainer: {
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            backgroundColor: theme.palette.background.grey,
            borderRadius: '50%',
            padding: '9rem',
            top: -30,
            left: -40,
            zIndex: -1
        }
    },
    quote: {
        fontSize: 20,
        fontStyle: 'italic',
        position: 'relative',
        '&::before': {
            content: 'open-quote',
            display: 'block',
            position: 'absolute',
            color: theme.palette.primary.main,
            fontSize: '5rem',
            transform: 'translateY(-50%)'
        },
        '&::after': {
            content: 'close-quote',
            display: 'block',
            position: 'absolute',
            color: theme.palette.primary.main,
            fontSize: '5rem'
        }
    },
    quoteAttribution: {
        textAlign: 'right'
    }
}));

export default function ServicesList() {
    const classes = useStyles();
    
    const handleItemClick = (subject) => {
        window.location = `mailto:scheduling@courtscribes.com?subject=${window.encodeURIComponent(subject)}`;
    };

    return (
        <List style={{paddingTop: `0`}}>
            <ListItem divider disableGutters style={{paddingTop: `0`}} classes={{ root: classes.scheduleItem }}>
                {/*<ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.serviceAvatar} alt="Schedule a Court Reporter for a Deposition" src="/static/schedule-icon.svg" />
                </ListItemAvatar>*/}
                <ListItemText>
                    <Typography style={{textTransform: `none`, fontSize: `16px`, xfontWeight: `normal`}} variant="h6" gutterBottom>{/*Schedule a Court Reporter for a Deposition*/}Court Reporters for Depositions...</Typography>
                    <Button style={{margin: `0 auto`}} xcolor="secondary" size="small" onClick={() => handleItemClick('Schedule A Deposition')}>Schedule Now</Button>
                </ListItemText>
            </ListItem>
            <ListItem divider disableGutters classes={{ root: classes.scheduleItem }}>
                {/*<ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.serviceAvatar} alt="Order Video/Audio Transcripts" src="/static/av_transcript-icon.svg" />
                </ListItemAvatar>*/}
                <ListItemText>
                    <Typography style={{textTransform: `none`, fontSize: `16px`, xfontWeight: `normal`}} variant="h6" gutterBottom>A.I.-Generated Transcripts...</Typography>
                    <Button style={{margin: `0 auto`}} xcolor="secondary" size="small" onClick={() => handleItemClick('A.I.-Generated Transcripts')}>Learn More</Button>
                </ListItemText>
            </ListItem>
            {/*<ListItem divider disableGutters classes={{ root: classes.scheduleItem }}>
                <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.serviceAvatar} alt="Order Video/Audio Transcripts" src="/static/av_transcript-icon.svg" />
                </ListItemAvatar>
                <ListItemText>
                    <Typography style={{textTransform: `none`, fontSize: `16px`, xfontWeight: `normal`}} variant="h6" gutterBottom>Audio/Video Transcription...</Typography>
                    <Button style={{margin: `0 auto`}} xcolor="secondary" size="small" onClick={() => handleItemClick('Order Video / Audio Transcripts')}>Order Now</Button>
                </ListItemText>
            </ListItem>*/}
        </List>
    );
}