import React from 'react';
import { Box, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../button';
import { useForm } from 'react-hook-form';
import { renderTextField } from '../form/TextField';
import { emailValidationOptions } from '../../helpers/validation';
import { auth } from 'firebase';
import useFormSubmission from '../../hooks/formSubmissionHook';
import ErrorList from '../form/ErrorList';
import { SuccessNotification } from '../../helpers/notifications';
import {getEnvSpecificEmailFromUsernameEmail} from '../../helpers/email';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
    }
}));

export default function ResetPasswordModal({
    onClose
}) {
    const classes = useStyles();
    const { handleSubmit, reset, formState, ...form } = useForm({
        defaultValues: {
            emailAddress: ''
        }
    });
    const { error, success, setError, setSuccess } = useFormSubmission();
    const disabled = !formState.isDirty || formState.isSubmitting;

    const onFormSubmit = ({ emailAddress }) => {
        const firebaseEmail = getEnvSpecificEmailFromUsernameEmail(emailAddress);
        auth().sendPasswordResetEmail(firebaseEmail)
            .then(() => {
                reset();
                setSuccess('Password reset instructions have been sent to your email');
            })
            .catch(setError)
    }

    return (
        <Dialog open onClose={onClose}>
            <IconButton className={classes.closeButton} aria-label="Close" onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Box component={DialogContent} display="flex" flexDirection="column">
                <DialogTitle color="primary">
                    Forgot Password
                </DialogTitle>
                <DialogContentText align="center">
                    Enter the email address associated with your account.
                </DialogContentText>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    {renderTextField(form)({
                        name: 'emailAddress',
                        placeholder: 'Your email address',
                        autocomplete: 'email',
                        validate: emailValidationOptions,
                        withFormControl: true,
                        showLabel: false
                    })}
                    {error && <ErrorList errors={[error.message]} />}
                    {success && <SuccessNotification message={success} />}
                    <Button centered type="submit" variant="contained" color="primary" loading={formState.isSubmitting} disabled={disabled}>
                        Reset Password
                    </Button>
                </form>
            </Box>
        </Dialog>
    );
}