import React, { useEffect, useState } from 'react';
import Button from '../button';
import { useStripe } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
import { useAccount, useAuth } from '../../hooks/authHook';
import api from '../../helpers/api';
import { CreditCardList, CreditCardListItem } from '../payments';
import useLoadingValue from '../../hooks/loadingHook';
import { Radio } from '../form';
import { Box, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import ErrorList from '../form/ErrorList';

const asCurrency = (number) => number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
});

const useStyles = makeStyles(theme => ({
    paymentBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    paymentBox: {
        backgroundColor: theme.palette.background.grey,
        padding: '24px 18px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    lineItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between'
    },
    bottomHeader: {
        fontSize: 16,
        fontWeight: 600
    },
    bottomCopy: {

    }
}));

export function PaymentSummaryBox(props) {
    const classes = useStyles();
    const hearingTotal = props.hearingFee || 0;

    return (
        <Box mt={1} mb={2}>
            <Typography variant="h5" align="center" gutterBottom>Payment Summary</Typography>
            <Paper elevation={0} className={classes.paymentBox}>
                <div className={classes.lineItem}>
                    <Typography component="span" variant="body1">Call-in Fee</Typography>
                    <Typography component="span">{asCurrency(hearingTotal)}</Typography>
                </div>
                <Divider />
                <div className={classes.lineItem}>
                    <Typography component="span" variant="subtitle2" color="textPrimary">TOTAL</Typography>
                    <Typography component="span" variant="subtitle2" color="textPrimary">{asCurrency(hearingTotal)}</Typography>
                </div>
            </Paper>
        </Box>
    );
}

export default function Payment(props) {
    const classes = useStyles();
    const { currentUser } = useAuth();
    const { currentAccount, accountLoading } = useAccount();
    const { hearingFee = 0, formSubmissionState } = props;
    const stripe = useStripe();
    const { value: paymentMethods, setValue: setPaymentMethods, loading: paymentsLoading, error: paymentsError } = useLoadingValue();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [addNewCard, setAddNewCard] = useState(false);
    const submitDisabled = hearingFee > 0 && (accountLoading || paymentsLoading || !selectedPaymentMethod || formSubmissionState.loading);

    useEffect(() => {
        const retrieveAccountPaymentMethods = async () => {
            const paymentMethods = await api('/api/accounts/retrievePaymentMethods');
            if (paymentMethods.length) {
                setPaymentMethods(paymentMethods);
                setSelectedPaymentMethod(paymentMethods[0].id);
            } else {
                setPaymentMethods([]);
                setAddNewCard(true);
            }
        };

        if (currentUser && currentAccount && currentAccount.stripeId) {
            retrieveAccountPaymentMethods();
        } else if (!accountLoading) {
            setPaymentMethods([]);
            setAddNewCard(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, currentAccount, accountLoading]);

    const handleSavedCardPayment = () => {
        return stripe.confirmCardPayment(props.paymentSecret, {
            payment_method: selectedPaymentMethod,
            receipt_email: currentAccount && currentAccount.emailAddress
        }).then(payload => {
            if (payload.error) {
                console.log('[error]', payload.error);
                return formSubmissionState.setError(payload.error);
            } else {
                if (payload.paymentIntent.status === 'succeeded') {
                    return props.onPaymentComplete && props.onPaymentComplete(payload.paymentIntent);
                }
            }
        });
    };

    const handleSubmitClick = () => {
        if (hearingFee > 0) {
            return handleSavedCardPayment();
        } else {
            return props.onPaymentComplete({
                id: null,
                amount: 0
            });   
        }
    };

    const renderPaymentOptions = () => {
        if (accountLoading || paymentsLoading) {
            return <p>Loading...</p>;
        }

        if (hearingFee > 0) {
            if (paymentMethods && paymentMethods.length) {
                return (
                    <CreditCardList
                        loading={paymentsLoading}
                        error={paymentsError}
                        paymentMethods={paymentMethods}
                    >
                        {paymentMethods.map(paymentMethod => (
                            <CreditCardListItem key={paymentMethod.id}
                                paymentMethod={paymentMethod}
                                paymentActions={
                                    <Radio
                                        value={paymentMethod.id}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                        checked={paymentMethod.id === selectedPaymentMethod}
                                    />
                                }
                                selected={paymentMethod.id === selectedPaymentMethod}
                            />
                        ))}
                    </CreditCardList>
                );
            }
        } else {
            return <h4>No payment required.</h4>;
        }
    };

    const renderAssistanceCopy = () => (
        <Box mt={4} mb={2}>
            <Divider />
            <Box mt={2}>
                <Typography className={classes.bottomHeader} component="strong" variant="body2">Need additional assistance?</Typography>
                <Typography variant="body1">
                    <Typography color="primary" component="span">
                        <Typography component="a" color="primary" href={`mailto:scheduling@courtscribes.com?subject=${window.encodeURIComponent('Schedule Assistance Request')}`}>Email us</Typography> <Typography component="span">or give us a call <Typography component="a" color="primary" href="tel:833-727-4237">833-727-4237</Typography></Typography>
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );

    if (addNewCard && hearingFee > 0) {
        const optionalProps = {};
        if (paymentMethods && paymentMethods.length) {
            optionalProps.onCancelClick = () => setAddNewCard(false);
        }

        return (
            <>
                <PaymentForm
                    currentAccount={currentAccount}
                    hearingFee={hearingFee}
                    paymentSecret={props.paymentSecret}
                    onPaymentComplete={props.onPaymentComplete}
                    formSubmissionState={formSubmissionState}
                    {...optionalProps}
                />
                {renderAssistanceCopy()}
            </>
        );
    }

    return (
        <Box mt={4}>
            <Grid container spacing={4}>
                <Grid item sm={7}>
                    <Box>
                        <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Payment Information</Typography>
                            {(currentAccount && hearingFee > 0) && <Button onClick={() => setAddNewCard(true)}>Add Payment Card</Button>}
                        </Box>
                        <Divider />
                        {renderPaymentOptions()}
                    </Box>
                </Grid>
                <Grid item sm={5}>
                    <PaymentSummaryBox hearingFee={hearingFee} />
                    {formSubmissionState.error && <ErrorList errors={[formSubmissionState.error.message]} />}
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        loading={formSubmissionState.loading}
                        disabled={submitDisabled}
                        onClick={formSubmissionState.submitHandler(handleSubmitClick)}
                        fullWidth
                    >
                        {hearingFee > 0 ? 'Submit Payment' : 'Complete Request'}
                    </Button>
                </Grid>
            </Grid>
            {renderAssistanceCopy()}
        </Box>
    );
}