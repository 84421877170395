import React from 'react';
import {
    Button as MuiButton,
    CircularProgress,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '1rem',
        paddingTop: 4,
        paddingBottom: 4,
    },
    contained: {
        fontWeight: 600,
        minWidth: '200px'
    },
    containedSizeSmall: {
        fontSize: '0.8125rem',
        fontWeight: 500,
        minWidth: '120px'
    },
    containedSecondary: {
        backgroundColor: '#FFFFFF',
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#FFFFFF'
        }
    },
    buttonWrapper: props => ({
        position: 'relative',
        display: 'flex',
        justifyContent: props.centered ? 'center' : 'flex-start',
        width: props.fullWidth ? '100%' : 'auto'
    }),
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
}));

export default function Button(props) {
    const { variant = 'contained', color = 'primary', fullWidth, loading, children, centered, ...attrs } = props;
    const { buttonWrapper, buttonProgress, ...classes } = useStyles({ centered, fullWidth });
    return (
        <div className={buttonWrapper}>
            <MuiButton classes={classes} variant={variant} color={color} fullWidth={fullWidth} {...attrs}>
                {children}
            </MuiButton>
            {loading && <CircularProgress size={24} className={buttonProgress} aria-busy={true} /> }
        </div>
    );
}