import React from 'react';
import { app } from '../../config';
import Logo from '../Logo';
import { NavLink, useHistory } from 'react-router-dom';
import { useAccount, useAuth } from '../../hooks/authHook.js';
import useHearings from '../../hooks/hearingsHook';
import { AuthNav } from '../AppBar';
import { Hidden, Typography, Toolbar, IconButton, useMediaQuery, Divider, darken } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    nav: {
        display: 'flex',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        marginTop: 2.5,
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2),
            width: '100%'
        }
    },
    navBadge: {
        position: 'absolute',
        right: '12px',
        fontSize: '14px'
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    heading: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h6.fontSize,
        [theme.breakpoints.down('sm')]: {
            fontSize: '.75rem',
            textAlign: 'center'
        }
    },
    separator: {
        margin: '8px 1.5rem 8px 4.75rem',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '1rem'
        }
    },
    footer: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '16px',
        fontSize: '12px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2)
        }
    },
    navLink: {
        margin: '4px 0',
        padding: '.6875rem 3rem .6875rem 4.75rem',
        textDecoration: 'none',
        color: theme.palette.text.secondary,
        position: 'relative',
        borderTopRightRadius: '24px',
        borderBottomRightRadius: '24px',
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            fontWeight: 600
        },
        '&:hover': {
            backgroundColor: darken(theme.palette.primary.main, .2),
            color: theme.palette.background.default
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '1rem'
        }
    },
    authContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

export default function Sidebar({ toggleMenu }) {
    const { currentUser, logout } = useAuth();
    const { setAccount } = useAccount();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { hearings, setHearings } = useHearings();
    const classes = useStyles();
    const AUTH_LINKS = [
        { title: 'Payments', href: '/payments' },
        { title: 'Account', href: '/account' }
    ];
    const handleLogoutClick = () => {
        logout();
        setAccount(null);
        setHearings(0);
        history.replace('/');
    };
    const logoSize = isMobile ? 60 : 80;

    return (
        <>
            <header className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Logo width={logoSize} height={logoSize} isMobile={isMobile} />
                    <Hidden mdUp>
                        <Typography className={classes.heading} variant="h2">
                            {app.appName}
                        </Typography>
                        <IconButton
                            color="primary"
                            aria-label="open menu"
                            onClick={toggleMenu}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Hidden>
                </Toolbar>
                <Hidden smUp>
                    <div className={classes.authContainer}>
                        <AuthNav currentUser={currentUser} onLogoutClick={handleLogoutClick} />
                    </div>
                </Hidden>
                <nav className={classes.nav}>
                    <NavLink
                        className={classes.navLink}
                        to={"/"}
                        onClick={toggleMenu}
                        exact
                    >Home</NavLink>
                    <NavLink
                        className={classes.navLink}
                        to={"/hearings"}
                        onClick={toggleMenu}
                        exact
                    >
                        Hearings
                        {hearings > 0 ? <span className={classes.navBadge}>{hearings}</span> : null}
                    </NavLink>
                    {currentUser && AUTH_LINKS.map((link) => (
                        <NavLink
                            key={link.title}
                            className={classes.navLink}
                            to={link.href}
                            onClick={toggleMenu}
                        >
                            {link.title}
                            {link.badge ? <span className={classes.navBadge}>{link.badge}</span> : null}
                        </NavLink>
                    ))}
                    <Divider className={classes.separator} />
                    <NavLink
                        className={classes.navLink}
                        to={"/contact-us"}
                        onClick={toggleMenu}
                    >Contact Us</NavLink>
                </nav>
            </header>
            <footer className={classes.footer}>
                <span>Copyright &copy; {`2013 - ${new Date().getFullYear()}`}</span>
                <span>CourtScribes, Inc.</span>
                <span>All Rights Reserved</span>
            </footer>
        </>
    );
}