import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export default function getUTCDateAndTimezone(localDateTime) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = zonedTimeToUtc(localDateTime, timezone);

    return {
        timezone,
        utc: utcDate.toISOString()
    };
}

export function parseUTCDateTime(utc, timezone) {
    const local = utcToZonedTime(utc, timezone);

    return {
        timezone,
        utc,
        local
    };
}