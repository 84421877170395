import React from 'react';
import {
    makeStyles,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Divider,
    Grid,
    Box
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const CC_MAP = {
    'visa': 'Visa',
    'mastercard': 'MasterCard',
    'discover': 'Discover'
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.grey
    },
    ccActionContainer: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end'
        }
    },
    ccActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

export default function CardItemRow({
    paymentMethod,
    paymentActions,
    selected
}) {
    const classes = useStyles();

    return (
        <>
            <ListItem className={selected ? classes.selected : ''}>
                <Grid container component={Box} alignItems="center">
                    <Grid item xs={6} sm={5}>
                        <Grid container component={Box} alignItems="center">
                            <Grid item sm={2}>
                                <ListItemAvatar>
                                    <CreditCardIcon />
                                </ListItemAvatar>
                            </Grid>
                            <Grid item sm={10}>
                                <ListItemText>
                                    <Typography variant="body2">
                                        {CC_MAP[paymentMethod.card.brand]} Credit Card
                                    </Typography>
                                </ListItemText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={7}>
                        <Grid container component={Box} alignItems="center" className={classes.ccActionContainer}>
                            <Grid item sm={7}>
                                <ListItemText>
                                    <Typography variant="body2">
                                        **** ****** **{paymentMethod.card.last4}
                                    </Typography>
                                </ListItemText>
                            </Grid>
                            {paymentActions && (
                                <Grid item sm={5}>
                                    <ListItemText className={classes.ccActions}>
                                        {paymentActions}
                                    </ListItemText>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </>
    );
}