import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Toolbar,
  Box,
  Divider,
  FormControl,
  OutlinedInput,
  Button,
  makeStyles,
} from '@material-ui/core';
import api from '../helpers/api';
import { useHistory } from 'react-router-dom';
import { useAccount, useAuth } from '../hooks/authHook';
import { apiUrl } from '../config';

const formatDate = (date) => {
  const d = new Date(date);
  const currDate = d.getDate();
  let currMonth = d.getMonth();
  currMonth++;
  const currYear = d.getFullYear();
  return `${currMonth}/${currDate}/${currYear}`;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    alignItems: 'baseline',
    minHeight: 'auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  search: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

export default function Payments() {
  const { currentUser } = useAuth();
  const { currentAccount } = useAccount();
  const history = useHistory();
  const classes = useStyles();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [payments, setPayments] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      setFetching(true);
      try {
        const payments = await api('/api/payments');
        setPayments(payments);
        setError('');
      } catch (error) {
        setError(error.message);
      } finally {
        setFetching(false);
      }
    };

    if (currentAccount) {
      fetchPayments();
    }
  }, [setPayments, currentAccount]);


  const searchChange = async (event, newValue) => {
    const term = event.target.value;
    setSearch(term);

    if (!term) {
      const payments = await api('/api/payments');
      setPayments(payments);
    }
    
    if (currentAccount) {
      const hearingsSearched = await api('/api/hearings/search', {
        query: { q: term },
      });

      const searched = hearingsSearched || [];

      const searchedPayments = searched.map((hearing) => ({
        id: hearing.payments[0].id,
        hearing,
        amount: hearing.payments[0].amount,
      }));

      setPayments(searchedPayments);
    }
  };

  const handleSearchChange = useCallback(searchChange, [payments]);

  const handleDownloadReceiptClick = (id) => {
    currentUser.getIdToken().then(token => {
      window.location.assign(`${apiUrl}/api/payments/${id}/downloadReceipt?token=${token}`);
    });
  };

  if (fetching) {
    return <Typography color="primary">Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Toolbar disableGutters className={classes.toolbar}>
      <Box mb={4}>
        <Typography color="primary" variant="h4">
          Payments
        </Typography>
      </Box>
        <FormControl className={classes.search} variant="outlined" margin="dense" size="small">
          <OutlinedInput
            placeholder="Search"
            value={search}
            onChange={(e) => {
              e.persist();
              handleSearchChange(e);
            }}
            startAdornment={
              <InputAdornment position="start">
                {/*<img
                  alt="search"
                  height={16}
                  src="https://cdns.iconmonstr.com/wp-content/assets/preview/2018/240/iconmonstr-search-thin.png"
                />*/}
              </InputAdornment>
            }
          />
        </FormControl>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Case No.</TableCell>
              <TableCell align="left">Job No.</TableCell>
              <TableCell align="left">Case Name</TableCell>
              <TableCell align="left">Fee</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments && payments.map((payment) => {
              return (
                <TableRow className={classes.tableRow} hover key={payment.id} onClick={() => history.push(`payments/${payment.id}`)}>
                  <TableCell component="th" scope="row">
                    {formatDate(payment.hearing.scheduledDate)}
                  </TableCell>
                  <TableCell align="left">#{payment.hearing.caseNumber}</TableCell>
                  <TableCell align="left">#{payment.hearing.jobId}</TableCell>
                  <TableCell align="left">{payment.hearing.caseName}</TableCell>
                  <TableCell align="left">${payment.amount}</TableCell>
                  <TableCell align="left">
                    <Button
                      startIcon={<img width={16} alt="pdf icon" src="/static/pdf-icon.png" />}
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownloadReceiptClick(payment.id);
                      }}
                    >
                        Download PDF
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
