import React from 'react';
import { Box, Card, CardContent, Container, Divider, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { renderTextField } from '../components/form/TextField';
import useFormSubmission from '../hooks/formSubmissionHook';
import { useForm } from 'react-hook-form';
import Button from '../components/button';
import { NewFormControl as FormControl } from '../components/form/FormControl';
import ErrorList from '../components/form/ErrorList';
import { emailValidationOptions } from '../helpers/validation';
import { SuccessNotification } from '../helpers/notifications';
import { ReactComponent as PhoneIcon } from '../components/icons/phone-icon.svg';
import { ReactComponent as EmailIcon } from '../components/icons/email-icon.svg';
import api from '../helpers/api';

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: 0
    },
    grid: {
        justifyContent: 'center'
    },
    headerContent: {
        backgroundColor: theme.palette.background.grey,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    icon: {
        marginBottom: theme.spacing(2)
    },
    roundedIcon: {
        borderRadius: '50%'
    }
}));

function ContactForm({
    form,
    formSubmissionState,
    onFormSubmit
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { handleSubmit, formState } = form;
    const { isDirty, isSubmitting } = formState;
    const { success, error } = formSubmissionState;
    const disabled = !isDirty || isSubmitting;

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            {renderTextField(form)({
                name: 'name',
                label: 'Name',
                placeholder: 'Your name',
                autocomplete: 'name',
                withFormControl: true,
                validate: {
                    required: 'Please enter your name'
                }
            })}
            {renderTextField(form)({
                name: 'email',
                label: 'Email',
                placeholder: 'Your email',
                autocomplete: 'email',
                withFormControl: true,
                validate: emailValidationOptions
            })}
            {renderTextField(form)({
                name: 'phone',
                label: 'Phone',
                placeholder: 'Your phone number',
                autocomplete: 'tel',
                withFormControl: true
            })}
            {renderTextField(form)({
                name: 'message',
                label: 'Message',
                placeholder: 'Your message',
                withFormControl: true,
                displayOnly: true,
                asTextArea: true,
                validate: {
                    required: 'Please enter a message'
                }
            })}
            <FormControl row>
                {error && <ErrorList errors={[error.message]} />}
                {success && <SuccessNotification message={success} />}
            </FormControl>
            <FormControl row>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    disabled={disabled}
                    fullWidth={isMobile}
                >
                    Send
                </Button>
            </FormControl>
        </form>
    );
}

export default function ContactPage() {
    const classes = useStyles();
    const { reset, ...form } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: ''
        }
    });
    const { submitHandler, setSuccess, setError, ...formSubmissionState } = useFormSubmission();
    const handleFormSubmit = (data) => {
        return api('/api/notifications/contactForm', {
            method: 'POST',
            body: data
        }).then(() => {
            reset();
            setSuccess('Your message was succesfully sent!');
        }).catch(() => {
            setError(new Error('An error occurred while submitting your form. Please try again.'));
        });
    };
    return (
        <Container className={classes.container} maxWidth="lg" disableGutters>
            <Typography variant="h4" color="primary" gutterBottom>Contact Us</Typography>
            <Grid container spacing={2} className={classes.grid}>
                <Grid item sm={6}>
                    <Card elevation={0}>
                        <CardContent className={classes.headerContent}>
                            <PhoneIcon className={`${classes.icon} ${classes.roundedIcon}`} />
                            <Box mb={1} display="flex" flexDirection="column" alignItems="center">
                                <Typography variant="subtitle2">Phone</Typography>
                                <Typography color="textSecondary">Toll Free 24/7 Service</Typography>
                            </Box>
                            <Typography variant="body1" color="primary" component="a" href="tel:833-727-4237">833-SCRIBES (727-4237)</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6}>
                    <Card elevation={0}>
                        <CardContent className={classes.headerContent}>
                            <EmailIcon className={classes.icon} />
                            <Box mb={1} display="flex" flexDirection="column" alignItems="center">
                                <Typography variant="subtitle2">Email</Typography>
                                <Typography color="textSecondary">Questions? We can help</Typography>
                            </Box>
                            <Typography variant="body1" color="primary" component="a" href="mailto:info@courtscribes.com">info@courtscribes.com</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box mb={3}>
                <Typography variant="h6" gutterBottom>Send Message</Typography>
                <Divider />
            </Box>
            <ContactForm
                form={form}
                formSubmissionState={formSubmissionState}
                onFormSubmit={submitHandler(handleFormSubmit)}
            />
        </Container>
    );
}