import React from 'react';
import { ReactComponent as CaseNumberIcon } from '../icons/case_number-icon.svg';
import { ReactComponent as CalendarIcon } from '../icons/calendar-icon.svg';
import { ReactComponent as ClockIcon } from '../icons/clock-icon.svg';
import { ReactComponent as JudgeIcon } from '../icons/judge-icon.svg';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    item: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    },
    body1: {
        fontSize: 12,
        fontWeight: 600
    }
}));

export default function HearingSummary({
    jobId,
    scheduledDate,
    judgeName,
    courtReporterRequested,
    aiTranscriptRequested
}) {
    const theme = useTheme();
    const classes = useStyles();
    const hearingDate = new Date(scheduledDate);

    return (
        <div className={classes.root}>
            <div className={classes.item}>
                <CaseNumberIcon width={20} height={20} fill={theme.palette.primary.main} />
                <Typography color="textSecondary" variant="body2">Job Id</Typography>
                <Typography color="textPrimary" className={classes.body1}>{jobId}</Typography>
            </div>
            <div className={classes.item}>
                <CalendarIcon width={20} height={20} fill={theme.palette.primary.main} />
                <Typography color="textSecondary" variant="body2">Hearing Date</Typography>
                <Typography color="textPrimary" className={classes.body1}>{format(hearingDate, 'MMMM dd, yyyy')}</Typography>
            </div>
            <div className={classes.item}>
                <ClockIcon width={20} height={20} fill={theme.palette.primary.main} />
                <Typography color="textSecondary" variant="body2">Time</Typography>
                <Typography color="textPrimary" className={classes.body1}>{format(hearingDate, 'h:mm aa')}</Typography>
            </div>
            <div className={classes.item}>
                <JudgeIcon width={20} height={20} fill={theme.palette.primary.main} />
                <Typography color="textSecondary" variant="body2">Judge</Typography>
                <Typography color="textPrimary" className={classes.body1}>{judgeName}</Typography>
            </div>
            {courtReporterRequested &&
                <div className={classes.item}>
                    <JudgeIcon width={20} height={20} fill={theme.palette.primary.main} />
                    <Typography color="textSecondary" variant="body2">Requested</Typography>
                    <Typography color="textPrimary" className={classes.body1}>Court Reporter</Typography>
                </div>
            }
            {aiTranscriptRequested &&
                <div className={classes.item}>
                    <JudgeIcon width={20} height={20} fill={theme.palette.primary.main} />
                    <Typography color="textSecondary" variant="body2">Requested</Typography>
                    <Typography color="textPrimary" className={classes.body1}>A.I. Transcript</Typography>
                </div>
            }


        </div>
    );
}