import React from 'react';
import { NewFormControl as FormControl } from '../form/FormControl';
import Button from '../button';
import AccountReview from './AccountReview';
import { useStyles as useAlertStyles } from './Information';
import { PaymentSummaryBox } from './Payment';
import { features } from '../../config';
import { useAccount } from '../../hooks/authHook';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import ErrorList from '../form/ErrorList';
import ParticipantsList from "../ParticipantsList";

const MAINE_STATE_ID = `20`;

export default function Review({
    formSubmissionState,
    formData = {},
    state = {},
    judge = {},
    onFormSubmit,
    onEditClick
}) {
    const classes = useAlertStyles();
    const { currentAccount } = useAccount();
    let accountData;
    if (currentAccount) {
        accountData = {
            accountName: `${currentAccount.firstName} ${currentAccount.lastName}`,
            firmName: currentAccount.firmName,
            accountEmail: currentAccount.emailAddress
        };
    } else {
        accountData = {
            accountName: formData.accountName,
            firmName: formData.firmName,
            accountEmail: formData.accountEmail
        }; 
    }

    return (
        <Grid container spacing={4}>
            <Grid item sm={7}>
                <Box mt={4} mb={4}>
                    <Box mb={3}>
                        <Box mb={1}>
                            <Typography variant="h6">Your Information</Typography>
                        </Box>
                        <Divider />
                    </Box>
                    <AccountReview {...accountData} />
                </Box>
                <Box mb={4}>
                    <Box mb={3}>
                        <Box mb={1}>
                            <Typography variant="h6">Case Information</Typography>
                        </Box>
                        <Divider />
                    </Box>
                    <FormControl label="Case Number" id="caseNumber" displayOnly>
                        <Typography variant="body2">{formData.caseNumber}</Typography>
                    </FormControl>
                    <FormControl label="Case Name" id="caseName" displayOnly>
                        <Typography variant="body2">{formData.caseName}</Typography>
                    </FormControl>
                    <FormControl label="Date" id="hearingDate" displayOnly>
                        <Typography variant="body2">{format(formData.scheduledDate, 'MMMM dd, yyyy')}</Typography>
                    </FormControl>
                    <FormControl label="Time" id="hearingTime" displayOnly>
                        <Typography variant="body2">{format(formData.scheduledDate, 'hh:mm aa')}</Typography>
                    </FormControl>
                    <FormControl label="State" id="state" displayOnly>
                        <Typography variant="body2">{state.name}</Typography>
                    </FormControl>
                    {formData.courtReporterRequested === 'true' && (
                        <FormControl>
                            <Alert icon={false} severity="info" classes={{
                                root: classes.root,
                                standardInfo: classes.standardInfo
                            }}>
                                <span className={classes.arrow} />
                                <Typography color="primary" variant="body2">* Confirmation: Court Reporter Requested *</Typography>
                            </Alert>
                        </FormControl>
                    )}
                    {formData.aiTranscriptRequested === 'true' && (
                        <FormControl>
                            <Alert icon={false} severity="info" classes={{
                                root: classes.root,
                                standardInfo: classes.standardInfo
                            }}>
                                <span className={classes.arrow} />
                                <Typography color="primary" variant="body2">* Confirmation: A.I. Transcript Requested *</Typography>
                            </Alert>
                        </FormControl>
                    )}

                    {false && formData.audioRecordingRequested === 'true' && (
                        <FormControl>
                            <Alert icon={false} severity="info" classes={{
                                root: classes.root,
                                standardInfo: classes.standardInfo
                            }}>
                                <span className={classes.arrow} />
                                <Typography color="primary" variant="body2">* Confirmation: Audio Recording Requested *</Typography>
                            </Alert>
                        </FormControl>
                    )}
                    <FormControl label="Judge" id="judge" displayOnly>
                        <Typography variant="body2">{judge.displayName}</Typography>
                    </FormControl>
                    {features.showParticipantListField && (
                        <FormControl label="Participant(s) Appearing" id="participantsAppearing" displayOnly>
                            <Typography variant="body2">
                                <ParticipantsList participantAppearing={formData.participantsAppearing} />
                            </Typography>
                            {/*{formData.participantsAppearing.map(participant => (
                                <>
                                    <Typography variant="body2" key={`${participant.name}${participant.email}`}>{participant.name}{` `}
                                    {
                                        participant.email || participant.phone
                                            ? <>({participant.email || ``}{participant.phone ?  `, ${participant.phone}` : ``})</>
                                            : <></>
                                    }
                                    </Typography>
                                </>


                            ))}*/}
                        </FormControl>
                    )}
                    {!features.showParticipantListField && (
                        <FormControl label="Participant(s) Appearing" id="participantAppearing" displayOnly>
                            <Typography variant="body2">{formData.participantAppearing}</Typography>
                        </FormControl>
                    )}
                    {
                        formData.partyRepresenting
                            ? <FormControl label="Party Representing" id="partyRepresenting" displayOnly>
                                    <Typography variant="body2">{formData.partyRepresenting}</Typography>
                                </FormControl>
                            : <></>
                    }

                    <FormControl label="Type of Hearing" id="hearingType" displayOnly>
                        <Typography variant="body2">{formData.hearingType}</Typography>
                    </FormControl>
                </Box>
                <FormControl row>
                    <Button variant="contained" color="secondary" onClick={onEditClick}>Edit Information</Button>
                </FormControl>
            </Grid>
            <Grid item sm={5}>
                <PaymentSummaryBox hearingFee={judge.hearingFee || 0} />
                {formSubmissionState.error && <ErrorList errors={[formSubmissionState.error.message]} />}
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={formSubmissionState.submitHandler(onFormSubmit)}
                    loading={formSubmissionState.loading}
                    disabled={formSubmissionState.loading}
                    fullWidth
                >Continue</Button>
            </Grid>
        </Grid>
    );
}