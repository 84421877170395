import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG  } from '../logo.svg'
import { app } from '../config';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    logoContainer: props => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: props.isMobile ? 'flex-start' : 'center',
        textDecoration: 'none',
        marginBottom: props.isMobile ? 0 : theme.spacing(2)
    }),
    tagLine: props => ({
        color: theme.palette.grey[700],
        letterSpacing: props.isMobile ? '0.56px' : '0.84px',
        textTransform: 'uppercase',
        fontSize: props.isMobile ? 7 : 11
    })
}));

export default function Logo({
    width = 80,
    height = 80,
    isMobile = false
}) {
    const classes = useStyles({ isMobile });

    return (
        <Link to="/" className={classes.logoContainer}>
            <LogoSVG width={width} height={height} />
            <Typography variant="subtitle1" className={classes.tagLine}>{app.appTagLine}</Typography>
        </Link>
    );
}