import React from 'react';
import {
    makeStyles,
    TextField as MuiTextField
} from '@material-ui/core';
import { NewFormControl } from './FormControl';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    },
    input: {
        fontSize: '14px',
        width: '100%',
        backgroundColor: '#FFFFFF'
    }
}));

export const TextField  = props => {
    const classes = useStyles();
    const { label, name, errors, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;

    return (
        <MuiTextField
            error={!!error}
            name={name}
            variant="outlined"
            size="small"
            className={classes.root}
            inputProps={{
                className: classes.input
            }}
            {...rest}
        />
    );
};

export const TextFieldWithFormControl = (props) => {
    const classes = useStyles();
    const { label, name, errors, showLabel, displayOnly, asTextArea, ...rest } = props;
    const error = errors && errors[name] && errors[name].message;
    const id = `text-${name}`;
    const additionalAttrs = {};
    if (asTextArea) {
        additionalAttrs.multiline = true;
        additionalAttrs.rows = 4;
    }

    return (
        <NewFormControl
            label={label}
            id={id}
            error={error}
            showLabel={showLabel}
            displayOnly={displayOnly}
        >
            <MuiTextField
                error={!!error}
                name={name}
                variant="outlined"
                size="small"
                className={classes.root}
                inputProps={{
                    className: classes.input,
                    ...rest
                }}
                {...additionalAttrs}
                {...rest}
            />
        </NewFormControl>
    );
};

export const renderTextField = ({
    control,
    errors
}) => ({
    name,
    label,
    placeholder,
    autocomplete,
    validate,
    type = 'text',
    withFormControl,
    asTextArea = false,
    ...rest
}) => (
    <Controller
        control={control}
        name={name}
        rules={validate}
        render={props => {
            const TextFieldComponent = withFormControl ?
                TextFieldWithFormControl : TextField;
            return <TextFieldComponent
                label={label}
                placeholder={placeholder}
                autocomplete={autocomplete || `on`}
                errors={errors}
                type={type}
                asTextArea={asTextArea}
                {...props}
                {...rest}
            />
        }}
    />
);

export default TextFieldWithFormControl;