import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import AppBarComponent from './components/AppBar';
import Sidebar from './components/sidebar';
import { ProvideAuth } from './contexts/authContext';
import { ProvideHearings } from './contexts/hearingsContext';
import HomePage from './pages/Home';
import HearingsPage from './pages/Hearings';
import HearingsDetailPage from './pages/HearingsDetail';
import PaymentsPage from './pages/Payments';
import PaymentsDetailPage from './pages/PaymentsDetail';
import SchedulePage from './pages/Schedule';
import ContactPage from './pages/Contact';
import LoginPage from './components/account/Login';
import RegisterPage from './components/account/Register';
import VerifyEmailPage from './components/account/VerifyEmail';
import { AccountPage, AccountPasswordPage, AccountPaymentPage } from "./pages/Account"
import { ProvideAccount, useAuth } from './hooks/authHook';
import theme from './theme';
import { ThemeProvider, CssBaseline, Hidden, Drawer, AppBar, makeStyles, Typography } from '@material-ui/core';

const drawerWidth = 235;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    backgroundColor: theme.palette.background.grey,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      maxWidth: theme.breakpoints.values.lg
    }
  },
  drawer: {
    backgroundColor: theme.palette.background.grey,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.grey,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0',
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      padding: '6px 0 8px',
      width: '100%',
      alignItems: 'flex-start'
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  }
}));

const MainLayout = props => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appBar}
        position="static"
        elevation={0}
        square
      >
        <AppBarComponent toggleMenu={handleDrawerToggle} />
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
                paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Sidebar toggleMenu={handleDrawerToggle} />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="js">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Sidebar />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {props.children}
      </main>
    </div>
  );
}

const AppRoute = ({ children, requireAuth, location, ...rest }) => {
  const { currentUser } = useAuth();

  if (requireAuth && !currentUser) {
    return <Redirect to={{
      pathname: "/signin",
      state: { returnUrl: location }
    }}
    />;
  }

  return (
    <Route {...rest} render={props => (
      <MainLayout {...props}>
        {children}
      </MainLayout>
    )}
    />
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAuth>
        <ProvideAccount>
          <ProvideHearings>
            <Router>
              <Switch>
                <Route path="/signin">
                  <LoginPage />
                </Route>
                <Route path="/signup">
                  <RegisterPage />
                </Route>
                <Route path="/verifyEmail">
                  <VerifyEmailPage />
                </Route>
                <AppRoute path="/hearings" exact>
                  <HearingsPage />
                </AppRoute>
                <AppRoute path="/hearings/schedule" exact>
                  <SchedulePage />
                </AppRoute>
                <AppRoute path="/hearings/:id" requireAuth exact>
                  <HearingsDetailPage />
                </AppRoute>
                <AppRoute path="/hearings/schedule/:hearingId">
                  <SchedulePage />
                </AppRoute>
                <AppRoute path="/payments" exact>
                  <PaymentsPage />
                </AppRoute>
                <AppRoute path="/payments/:paymentId" requireAuth exact>
                  <PaymentsDetailPage />
                </AppRoute>
                <AppRoute path="/account/password" requireAuth>
                  <AccountPasswordPage />
                </AppRoute>
                <AppRoute path="/account/payment" requireAuth>
                  <AccountPaymentPage />
                </AppRoute>
                <AppRoute path="/account" requireAuth>
                  <AccountPage />
                </AppRoute>
                <AppRoute path="/contact-us">
                  <ContactPage />
                </AppRoute>
                <AppRoute path="/" exact>
                  <HomePage />
                </AppRoute>
                <AppRoute path="*">
                  <Typography variant="h4" color="primary">Page Not Found</Typography>
                </AppRoute>
              </Switch>
            </Router>
          </ProvideHearings>
        </ProvideAccount>
      </ProvideAuth>
    </ThemeProvider>
  );
}

export default App;
