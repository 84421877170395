import React from 'react';
import { Typography } from '@material-ui/core';


/**
 * @param participantAppearing From the hearings.participant_appearing field, either the name of a participant,
 * or an array of objects, each representing a participant {name, email, phone}, or a JSON representation of
 * such an array.
 * @returns {JSX.Element}
 * @constructor
 */
export default function ParticipantsList({participantAppearing}) {
    let participantsArr;
    if (!participantAppearing) {
        participantsArr = [];
    } else if (Array.isArray(participantAppearing)) {
        participantsArr = participantAppearing;
    } else if (participantAppearing.startsWith(`[`)) {
        participantsArr = JSON.parse(participantAppearing);
    } else {
        participantsArr = [{name: participantAppearing, email: ``, phone: ``}];
    }
    return (
        participantsArr.map(participant => (
            <div style={{whiteSpace: `nowrap`}}>{participant.name}
                {
                    participant.email || participant.phone
                        ? <>{` `}({participant.email || ``}{participant.phone ?  `, ${participant.phone}` : ``})</>
                        : <></>
                }
            </div>
        ))
    );
}