
import React, { useEffect, useState } from 'react';
import Button from '../components/button';
import { NavLink, useLocation } from "react-router-dom";
import Arrow from '../components/Arrow';
import { Controller, useForm } from 'react-hook-form';
import { useAccount } from '../hooks/authHook';
import { emailValidationOptions, fullNameValidationOptions, passwordConfirmValidationOptions, passwordValidationOptions } from '../helpers/validation';
import { Select, StripeElements, TextField } from '../components/form';
import useApi from '../hooks/apiHook';
import { app } from '../config';
import api from '../helpers/api';
import ErrorList from '../components/form/ErrorList';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useElements,
    useStripe } from '@stripe/react-stripe-js';
import { CreditCardList, CreditCardListItem } from '../components/payments';
import { NewFormControl } from '../components/form/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, Select as MuiSelect, makeStyles, useTheme, useMediaQuery, withStyles, Divider, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CirclePlusIcon from '@material-ui/icons/ControlPoint';
import { SuccessNotification } from '../helpers/notifications';
import useFormSubmission from '../hooks/formSubmissionHook';
import firebase from 'firebase/app';
import {getEnvSpecificEmailFromUsernameEmail} from '../helpers/email';


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    leftNav: {
        maxWidth: '249px',
        width: '100%',
        padding: '16px 24px',
        borderRadius: '4px',
        backgroundColor: theme.palette.background.grey
    },
    navLink: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '4px',
        textDecoration: 'none',
        padding: '10px 12px',
        fontSize: 16,
        color: '#545454',
        '& > span': {
            display: 'none'
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
            fontWeight: 500,
            '& > span': {
                display: 'block'
            }
        }
    },
    mobileNav: {
        width: '100%',
        cursor: 'pointer',
        marginBottom: theme.spacing(3)
    },
    mobileNavLink: {
        display: 'block',
        fontSize: 16,
        color: theme.palette.text.primary,
        textDecoration: 'none',
        width: '100%'
    },
    content: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4)
        }
    },
    infoSection: {
        flex: 1,
        width: '100%'
    },
    input: {
        fontSize: '14px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        width: '100%'
    }
}));

const RemoveButton = withStyles(() => ({
    root: {
        color: '#E00000'
    }
}))(Button);

const renderTextField = ({
    control,
    errors
}) => ({
    name,
    label,
    placeholder,
    validate,
    autocomplete,
    type = 'text'
}) => (
    <Controller
        control={control}
        name={name}
        rules={validate}
        render={props =>
            <TextField
                label={label}
                placeholder={placeholder}
                autocomplete={autocomplete || `on`}
                errors={errors}
                type={type}
                {...props}
            />
        }
    />
);

function LeftNavigatorSection() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    return isMobile ?
        <MuiSelect
            variant="outlined"
            size="small"
            className={classes.mobileNav}
            SelectDisplayProps={{
                className: classes.input
            }}
            value={location.pathname}
            IconComponent={ExpandMoreIcon}
        >
            <MenuItem value="/account">
                <NavLink className={classes.mobileNavLink} to="/account" exact>Profile</NavLink>
            </MenuItem>
            <MenuItem value="/account/password">
                <NavLink className={classes.mobileNavLink} to="/account/password">Password</NavLink>
            </MenuItem>
            <MenuItem value="/account/payment">
                <NavLink className={classes.mobileNavLink} to="/account/payment">Payment</NavLink>
            </MenuItem>
        </MuiSelect>
        :
        <div className={classes.leftNav}>
            <NavLink className={classes.navLink} to="/account" exact>Profile<Arrow color={theme.palette.background.default} direction="right" /></NavLink>
            <NavLink className={classes.navLink} to="/account/password">Password<Arrow color={theme.palette.background.default} direction="right" /></NavLink>
            <NavLink className={classes.navLink} to="/account/payment">Payment<Arrow color={theme.palette.background.default} direction="right" /></NavLink>
        </div>;
}

function AccountContainer({ children }) {
    const classes = useStyles();
    return (
        <section>
            <Typography color="primary" variant="h5" gutterBottom>
                Account
            </Typography>
            <div className={classes.wrapper}>
                <LeftNavigatorSection />
                <div className={classes.infoSection}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
}

function InformationForm({ currentAccount, onFormSubmit, formSubmissionState }) {
    /* Note: this form used to have an "Email" field, but I've commented out anything related to it, because
    it wasn't "hooked up" to anything.  When we want to, we can un-comment and properly implement it. - S.C.S.
     */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { value: states } = useApi('/api/states');
    const { control, errors, setValue, handleSubmit, formState } = useForm({
        defaultValues: {
            accountName: currentAccount.displayName,
            // accountEmail: currentAccount.emailAddress,
            firmName: currentAccount.firmName,
            firmAddressOne: currentAccount.firmAddressOne,
            firmAddressTwo: currentAccount.firmAddressTwo,
            firmCity: currentAccount.firmCity,
            firmStateId: '',
            firmZipCode: currentAccount.firmZipCode
        }
    });
    const { isDirty, isSubmitting } = formState;
    const disabled = !isDirty || isSubmitting;
    const { error, success } = formSubmissionState;

    useEffect(() => {
        if (currentAccount.firmStateId && (states && states.length)) {
            setValue('firmStateId', currentAccount.firmStateId);
        }
    }, [currentAccount, states, setValue]);

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            {renderTextField({
                control,
                errors
            })({
                name: 'accountName',
                label: 'Name',
                placeholder: 'Your Name',
                autocomplete: 'name',
                validate: fullNameValidationOptions
            })}
            {renderTextField({
                control,
                errors
            })({
                name: 'firmName',
                label: 'Firm',
                placeholder: 'Firm Name',
                autocomplete: 'organization',
                validate: { required: 'Please enter the name of your firm' }
            })}
            {renderTextField({
                control,
                errors
            })({
                name: 'firmAddressOne',
                label: 'Firm Address',
                autocomplete: 'address-line1',
                placeholder: '123 Main St'
            })}
            {renderTextField({
                control,
                errors
            })({
                name: 'firmAddressTwo',
                autocomplete: 'address-line2',
                placeholder: 'Apartment, Suite No'
            })}
            {renderTextField({
                control,
                errors
            })({
                name: 'firmCity',
                autocomplete: 'address-level2',
                label: 'Firm City'
            })}
            <Controller
                name="firmStateId"
                control={control}
                rules={{ required: 'Please select a state' }}
                render={props =>
                    <Select
                        label="State"
                        errors={errors}
                        {...props}
                    >
                        <MenuItem value="">Select a state</MenuItem>
                        {(states && states.length > 0) && states.map(state => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>)}
                    </Select>
                }
            />
            {renderTextField({
                control,
                errors
            })({
                name: 'firmZipCode',
                autocomplete: 'postal-code',
                label: 'Firm Zip Code'
            })}
            {/*
            // See comment above re removing the "Email" field for now...
            {renderTextField({
                control,
                errors
            })({
                name: 'accountEmail',
                label: 'Email',
                type: 'email',
                autocomplete: 'email',
                validate: emailValidationOptions
            })}*/}
            <NewFormControl gutterBottom={false}>
                {error && <ErrorList errors={[error.message]} />}
                {success && <SuccessNotification message={success} />}
            </NewFormControl>
            <NewFormControl row>
                <Button type="submit" fullWidth={isMobile} loading={isSubmitting} disabled={disabled}>Save</Button>
            </NewFormControl>
        </form>
    );
}

function AddPaymentMethodForm({
    setupIntent,
    currentAccount,
    onCardAddComplete,
    onCardAddCancel,
    setFormSubmissionState,
    formSubmissionState
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { handleSubmit, formState, getValues, ...form } = useForm({
        defaultValues: {
            name: ''
        }
    });
    const stripe = useStripe();
    const elements = useElements();
    const { isDirty, isSubmitting } = formState;
    const disabled = (!stripe || !elements) || !isDirty || isSubmitting;

    const onFormSubmit = async () => {
        if (disabled) {
            return;
        }

        const card = elements.getElement(CardNumberElement);
        const payload = await stripe.confirmCardSetup(setupIntent.client_secret, {
            payment_method: {
                card,
                billing_details: {
                    name: getValues('name'),
                    email: currentAccount.emailAddress
                }
            }
        });

        if (payload.error) {
            console.log('[error]', payload.error);
            setFormSubmissionState({
                error: payload.error,
                success: null
            });
        } else {
            console.log('[SetupIntent]', payload.setupIntent);
            if (payload.setupIntent.status === 'succeeded') {
                if (onCardAddComplete) {
                    return onCardAddComplete(payload.setupIntent);
                }
            }
        }
    };

    return (
        <Box mt={4}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                {renderTextField(form)({
                    label: 'Name on Card',
                    name: 'name',
                    placeholder: 'Cardholder Name',
                    autocomplete: 'cc-name',
                    validate: { required: 'Please enter the name on the card' }
                })}
                <NewFormControl label="Card Number" id="cardNumber">
                    <CardNumberElement
                        id="cardNumber"
                        placeholder="Your card number"
                        options={StripeElements.ELEMENT_OPTIONS}
                    />
                </NewFormControl>
                <NewFormControl label="Expiration Date" id="expiry">
                    <CardExpiryElement
                        id="expiry"
                        options={StripeElements.ELEMENT_OPTIONS}
                    />
                </NewFormControl>
                <NewFormControl label="CVC Code" id="cvc">
                    <CardCvcElement
                        id="cvc"
                        options={StripeElements.ELEMENT_OPTIONS}
                    />
                </NewFormControl>
                <NewFormControl gutterBottom={false}>
                    {(formSubmissionState && formSubmissionState.error) && <ErrorList errors={[formSubmissionState.error.message]} />}
                </NewFormControl>
                <NewFormControl row>
                    <Button type="button" fullWidth={isMobile} onClick={onCardAddCancel} color="secondary">Cancel</Button>
                    <Button type="submit" fullWidth={isMobile} loading={isSubmitting} disabled={disabled}>Save</Button>
                </NewFormControl>
            </form>
        </Box>
    );
}

function PasswordForm({ form, onFormSubmit, formSubmissionState }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { handleSubmit, formState, watch } = form;
    const { isDirty, isSubmitting } = formState;
    const { error, success } = formSubmissionState;
    const disabled = !isDirty || isSubmitting;
    const newPassword = watch('newPassword');

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            {renderTextField(form)({
                name: 'currentPassword',
                label: 'Current Password',
                placeholder: 'Your password',
                autocomplete: 'current-password',
                type: 'password',
                validate: {
                    required: 'Please enter your current password',
                    minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters'
                    }
                }
            })}
            {renderTextField(form)({
                name: 'newPassword',
                label: 'New Password',
                placeholder: 'New password',
                autocomplete: 'new-password',
                type: 'password',
                validate: passwordValidationOptions
            })}
            {renderTextField(form)({
                name: 'newPasswordConfirm',
                label: 'Confirm',
                placeholder: 'Confirm password',
                autocomplete: 'new-password',
                type: 'password',
                validate: passwordConfirmValidationOptions(newPassword)
            })}
            <NewFormControl gutterBottom={false}>
                {error && <ErrorList errors={[error.message]} />}
                {success && <SuccessNotification message={success} />}
            </NewFormControl>
            <NewFormControl row>
                <Button type="submit" color="primary" fullWidth={isMobile} loading={isSubmitting} disabled={disabled}>Save</Button>
            </NewFormControl>
        </form>
    );
}

export const AccountPaymentPage = () => {
    const { currentAccount, accountLoading } = useAccount();
    const stripe = loadStripe(app.stripePublishableKey);
    const [addNewCard, setAddNewCard] = useState(false);
    const [setupIntent, setSetupIntent] = useState();
    const [formSubmissionState, setFormSubmissionState] = useState();
    const { value: paymentMethods, setValue: setPaymentMethods, loading: paymentsLoading, error: paymentsError } = useApi('/api/accounts/retrievePaymentMethods');

    const onCardAddComplete = async () => {
        const paymentMethods = await api('/api/accounts/retrievePaymentMethods');
        setPaymentMethods(paymentMethods);
        setFormSubmissionState({
            error: null,
            success: 'Your payment method has been saved!'
        });
        setAddNewCard(false);
    };
    const onCardAddCancel = () => {
        setAddNewCard(false);
    };
    const removeCardFromAccount = id => api('/api/accounts/removeCardFromAccounts', {
        method: 'POST',
        body: {
            paymentMethod: id
        }
    }).then(deletedPaymentMethod => {
        setFormSubmissionState({
            error: null,
            success: 'Your payment method has been deleted.'
        });
        setPaymentMethods(paymentMethods.filter(({id}) => id  !== deletedPaymentMethod.id));

    }).catch(error => {
        console.error(error);
    });

    const renderAddNewCardForm = () => {
        return (
            <Elements stripe={stripe}>
                <AddPaymentMethodForm
                    setupIntent={setupIntent}
                    currentAccount={currentAccount}
                    onCardAddComplete={onCardAddComplete}
                    onCardAddCancel={onCardAddCancel}
                    formSubmissionState={formSubmissionState}
                    setFormSubmissionState={setFormSubmissionState}
                />
            </Elements>
        );
    };

    useEffect(() => {
        // retrieve setup intent via api before
        // initializing stripe and rendering form
        if (addNewCard) {
            api('/api/accounts/createSetupIntent', {
                method: 'POST'
            }).then(setupIntent => setSetupIntent(setupIntent));
        }
    }, [addNewCard]);


    return (
        <AccountContainer>
            <Box mb={1}>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h6">{addNewCard ? 'Add new card:' : 'Your Payment Information'}</Typography>
                    {!addNewCard && 
                        <Button
                            variant="text"
                            startIcon={<CirclePlusIcon />}
                            onClick={() => setAddNewCard(true)}>
                                Add Payment Card
                        </Button>
                    }
                </Box>
            </Box>
            <Divider />
            {(!accountLoading && addNewCard && setupIntent) && renderAddNewCardForm()}
            {(!accountLoading && !addNewCard) && (
                <>
                    <CreditCardList
                        loading={paymentsLoading}
                        error={paymentsError}
                        paymentMethods={paymentMethods}
                    >
                        {paymentMethods && paymentMethods.map(paymentMethod => {
                            return (
                                <CreditCardListItem key={paymentMethod.id}
                                    paymentMethod={paymentMethod}
                                    paymentActions={<RemoveButton variant="text" onClick={() => removeCardFromAccount(paymentMethod.id)}>Remove Card</RemoveButton>}
                                />
                            );
                        })}
                    </CreditCardList>
                    {(formSubmissionState && formSubmissionState.success) && <SuccessNotification message={formSubmissionState.success} />}
                </>
            )}
        </AccountContainer>
    );
}

export const AccountPasswordPage = () => {
    const { currentAccount } = useAccount();
    const { reset, ...form } = useForm({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: ''
        }
    });
    const formSubmissionState = useFormSubmission();
    const handleFormSubmit = ({ currentPassword, newPassword, newPasswordConfirm }) => {
        const currentUser = firebase.auth().currentUser;
        const firebaseEmail = getEnvSpecificEmailFromUsernameEmail(currentAccount.emailAddress);
        const credential = firebase.auth.EmailAuthProvider.credential(firebaseEmail, currentPassword);
        return currentUser.reauthenticateWithCredential(credential).then(() => {
            return currentUser.updatePassword(newPassword)
                .then(() => {
                    reset();
                    formSubmissionState.setSuccess('Your password has been updated!');
                }).catch;
        }).catch(formSubmissionState.setError);
    };

    return (
        <AccountContainer>
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>Update Password</Typography>
                <Divider />
            </Box>
            <PasswordForm
                form={form}
                onFormSubmit={formSubmissionState.submitHandler(handleFormSubmit)}
                formSubmissionState={formSubmissionState}
            />
        </AccountContainer>
    );
}

export const AccountPage = () => {
    const { currentAccount, accountLoading, setAccount } = useAccount();
    const accountReady = !accountLoading && currentAccount;
    const formSubmissionState = useFormSubmission();
    const handleFormSubmit = formData => {
        const [accountFirstName, accountLastName] = formData.accountName.split(' ');
        formSubmissionState.reset();
        return api('/api/accounts/updateAccount', {
            method: 'POST',
            body: {
                accountFirstName,
                accountLastName,
                // accountEmail: formData.accountEmail,
                firmName: formData.firmName,
                firmAddressOne: formData.firmAddressOne,
                firmAddressTwo: formData.firmAddressTwo,
                firmCity: formData.firmCity,
                firmStateId: Number(formData.firmStateId),
                firmZipCode: formData.firmZipCode
            }
        }).then(updatedAccount => {
            formSubmissionState.setSuccess('Your account has been updated!');
            setAccount(updatedAccount);
        }).catch(error => {
            console.error(error);
            formSubmissionState.setError(error);
        });
    };

    return (
        <AccountContainer>
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>Your information</Typography>
                <Divider />
            </Box>
            {accountReady &&
                <InformationForm
                    currentAccount={currentAccount}
                    onFormSubmit={handleFormSubmit}
                    formSubmissionState={formSubmissionState}
                />
            }
        </AccountContainer>
    )
}