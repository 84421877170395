import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Toolbar,
  Box,
  Divider,
  FormControl,
  OutlinedInput,
  Grid,
  Card,
  LinearProgress,
  Button,
  CardContent,
  makeStyles,
  withStyles,
  darken,
} from '@material-ui/core';
import api from '../helpers/api';
import { Link, useHistory } from 'react-router-dom';
import { useAccount } from '../hooks/authHook';

const formatDate = (date) => {
  const d = new Date(date);
  const currDate = d.getDate();
  let currMonth = d.getMonth();
  currMonth++;
  const currYear = d.getFullYear();
  return `${currMonth}/${currDate}/${currYear}`;
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bar: {
    backgroundColor: theme.palette.secondary.main,
    borderRightStyle: 'solid',
    borderRightWidth: 2,
    borderRightColor: theme.palette.background.grey,
  },
}))(LinearProgress);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    minHeight: 'auto',
    '& > .MuiTab-wrapper': {
      flexDirection: 'row',
    },
  },
  selected: {
    fontWeight: 600,
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  headerCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerContent: {
    height: '100%',
    backgroundColor: theme.palette.background.grey,
    display: 'flex',
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  scheduleContent: {
    backgroundColor: theme.palette.background.grey,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  scheduleTitle: {
    marginTop: '0.4em',
    marginBottom: '0.4em',
  },
  headerTotal: {
    width: 220,
    minHeight: '8em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderRightColor: darken(theme.palette.background.grey, 0.1),
  },
  headerProgress: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerLegend: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  scheduleButton: {
    minWidth: 200,
  },
  upcomingIndicator: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
    marginBottom: '0 !important',
  },
  pastIndicator: {
    backgroundColor: theme.palette.primary.main,
    minWidth: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
    marginBottom: '0 !important',
  },
  draftIndicator: {
    backgroundColor: theme.palette.text.secondary,
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
    marginBottom: '0 !important',
  },
  toolbar: {
    alignItems: 'baseline',
    minHeight: 'auto',
    marginTop: theme.spacing(4),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  search: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  indicator: {
    height: 4,
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

export default function Hearings() {
  const { currentAccount } = useAccount();
  const history = useHistory();
  const classes = useStyles();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [hearings, setHearings] = useState(null);

  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchHearings = async () => {
      setFetching(true);
      try {
        const hearings = await api('/api/hearings', {
          query: {
            time: 'upcoming',
            status: 'Complete'
          }
        });
        setHearings(hearings);
        setError('');
      } catch (error) {
        setError(error.message);
      } finally {
        setFetching(false);
      }
    };

    if (currentAccount) {
      fetchHearings();
    }
  }, [setHearings, currentAccount]);

  const handleTabChange = useCallback(async (event, newValue) => {
    setTab(newValue);
    const status = newValue === 2 ? 'Incomplete' : 'Complete'
    if (currentAccount) {
      const hearings = await api('/api/hearings', {
        query: {
          time: newValue === 1 ? 'past' : 'upcoming',
          status
        },
      });
      setHearings(hearings);
    }
  }, [currentAccount]);

  const searchChange = async (event, newValue) => {
    const term = event.target.value;
    setSearch(term);

    if (!term) {
      return handleTabChange(null, tab);
    }
    
    if (currentAccount) {
      const hearingsSearched = await api('/api/hearings/search', {
        query: { q: term },
      });

      setHearings({ ...hearings, data: hearingsSearched });
    }
  };

  const handleSearchChange = useCallback(searchChange, [hearings]);

  if (fetching) {
    return <Typography color="primary">Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const total = (hearings && hearings.totalCount) || 0;
  const MIN = 0;
  const MAX = total;

  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

  return (
    <>
      <Typography color="primary" variant="h4" gutterBottom>
        Hearings
      </Typography>
      <Typography variant="h6" gutterBottom>
        Your hearings
      </Typography>

      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <Card elevation={0} className={classes.headerCard}>
            <CardContent className={classes.headerContent}>
              <div className={classes.headerTotal}>
                <div>
                  <Typography align="center" variant="h3">
                    {total}
                  </Typography>
                  <Typography align="center" variant="subtitle2">
                    TOTAL
                  </Typography>
                  <Typography align="center" variant="subtitle2">
                    HEARINGS
                  </Typography>
                </div>
              </div>
              <div className={classes.headerProgress}>
                {hearings &&
                  <>
                    <Box />
                    <div>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6">
                          {hearings.upcomingCount}
                        </Typography>
                        <Typography variant="h6">{hearings.pastCount}</Typography>
                      </Box>
                      <BorderLinearProgress
                        variant="determinate"
                        value={normalise(hearings.upcomingCount)}
                      />
                    </div>
                    <div className={classes.headerLegend}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pr={1}
                      >
                        <div className={classes.upcomingIndicator} />
                        <Typography color="textSecondary" variant="caption">
                          UPCOMING HEARINGS
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div className={classes.pastIndicator} />
                        <Typography color="textSecondary" variant="caption">
                          PAST HEARINGS
                        </Typography>
                      </Box>
                    </div>
                  </>
                }
                {!hearings &&
                  <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center">
                    <Typography>You have no hearings.</Typography>
                    <Typography component={Link} to="/hearings/schedule">Schedule a virtual hearing</Typography>
                  </Box>
                }
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={0}>
            <CardContent className={classes.scheduleContent}>
              <img width={50} src="/static/screen.png" alt="screen" />
              <Typography
                className={classes.scheduleTitle}
                gutterBottom
                variant="h6"
                align="center"
              >
                Schedule your virtual hearing
              </Typography>
              <Button
                component={Link}
                to="/hearings/schedule"
                className={classes.scheduleButton}
                variant="contained"
                color="primary"
              >
                Schedule now
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Toolbar disableGutters className={classes.toolbar}>
        <Tabs
          value={tab}
          indicatorColor={tab ? 'primary' : 'secondary'}
          textColor={tab ? 'primary' : 'secondary'}
          onChange={handleTabChange}
          classes={{ indicator: classes.indicator }}
        >
          <AntTab
            label="Upcoming"
            icon={<div className={classes.upcomingIndicator} />}
          />
          <AntTab
            label="Past"
            icon={<div className={classes.pastIndicator} />}
          />
          <AntTab
            label="Draft"
            icon={<div className={classes.draftIndicator} />}
          />
        </Tabs>
        <FormControl className={classes.search} variant="outlined" margin="dense" size="small">
          <OutlinedInput
            placeholder="Search"
            value={search}
            onChange={(e) => {
              e.persist();
              handleSearchChange(e);
            }}
            startAdornment={
              <InputAdornment position="start">
                {/*<img
                  alt="search"
                  height={16}
                  src="https://cdns.iconmonstr.com/wp-content/assets/preview/2018/240/iconmonstr-search-thin.png"
                />*/}
              </InputAdornment>
            }
          />
        </FormControl>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Case Number</TableCell>
              <TableCell align="left">Job Number</TableCell>
              <TableCell align="left">Case Name</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hearings && hearings.data.map((hearing) => {
              const status = hearing.status === 'Incomplete' ? 'Draft' : new Date(hearing.scheduledDate) < new Date() ? 'Past' : 'Upcoming';
              return (
                <TableRow className={classes.tableRow} hover key={hearing.jobId} onClick={() => history.push(`hearings/${hearing.jobId}`)}>
                  <TableCell component="th" scope="row">
                    {formatDate(hearing.scheduledDate)}
                  </TableCell>
                  <TableCell align="left">#{hearing.caseNumber}</TableCell>
                  <TableCell align="left">#{hearing.jobId}</TableCell>
                  <TableCell align="left">{hearing.caseName}</TableCell>
                  <TableCell align="left">
                    <Typography component="span" variant="subtitle2" color={ status === 'Draft' ? 'textSecondary' : status === 'Past' ? 'primary' : 'secondary'}>{status}</Typography>
                  </TableCell>
                  <TableCell align="left">${(hearing.judge && hearing.judge) ? hearing.judge.hearingFee : 0}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
