import { useCallback, useReducer } from "react";

const defaultState = ({
    loading: false,
    error: null,
    success: null
});

const reducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return defaultState;
        case 'loading':
            return {
                ...state,
                loading: action.toggle
            };
        case 'error':
            return {
                ...state,
                error: action.error,
                success: null
            };
        case 'success':
            return {
                ...state,
                error: null,
                success: action.success
            };
        default:
            return state;
    }
};

export default function useFormSubmission() {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const reset = () => {
        dispatch({ type: 'reset' });
    };
    const setLoading = (toggle) => {
        dispatch({ type: 'loading', toggle });
    };
    const setError = (error) => {
        dispatch({ type: 'error', error });
    };
    const setSuccess = (success) => {
        dispatch({ type: 'success', success });
    };

    const submitHandler = useCallback((callback) => async (event) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        setLoading(true);
    
        try {
            await callback(event);
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        loading: state.loading,
        error: state.error,
        success: state.success,
        reset,
        setError,
        setSuccess,
        submitHandler
    };
}