import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export function parseFirebaseError(err) {
    switch(err.code) {
        case 'auth/user-not-found':
            return <span>User login not found. Please <Typography variant="body2" color="primary" component={Link} to="/signup">sign up</Typography> or <Typography variant="body2" color="primary" component={Link} to="/verifyEmail">finish account setup</Typography>.</span>;
        case 'auth/wrong-password':
            return 'Email/password combination not found.';
        case 'auth/email-already-in-use':
            return 'The email address is already in use by another account.';
        case 'auth/too-many-requests':
            return 'Your account has been temporarily disabled due to too many login requests.';
        default:
            return 'An unknown authentication error occurred.';
    }
}

export default class ApiError extends Error {
    constructor(message, status = 500, code) {
        super(message);
        this.status = status;
        this.code = code;
    }
}