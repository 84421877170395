import React from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    StepConnector as MuiStepConnector,
    makeStyles,
    withStyles,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

const StepConnector = withStyles(theme => ({
    alternativeLabel: {
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
    },
    completed: {
        '& > span': {
            borderColor: theme.palette.primary.main
        },
    },
}))(MuiStepConnector);

const useStepIconStyles = makeStyles(theme => ({
    root: {
        color: 'transparent',
        width: 24,
        height: 24,
        borderRadius: '50%',
        border: '2px solid #E3E3E3'
    },
    active: {
        backgroundColor: '#E3E3E3',
        borderColor: theme.palette.primary.main
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    },
    check: {
        color: theme.palette.background.default,
        fontSize: 20
    }
}));

function StepIcon(props) {
    const { active, completed } = props;
    const classes = useStepIconStyles({
        active,
        completed
    });

    return (
        <div className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed
        })}>
            {completed ?
                <CheckIcon className={classes.check} />
                :
                <div />
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 424
        }
    },
    alternativeLabel: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: 6
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0
        }
    }
}));

const useLabelStyles = makeStyles(theme => ({
    label: {
        display: 'none',
        marginTop: 10,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    active: {
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
}))

export default function FormStepper({
    activeStep,
    steps
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles({ isMobile });
    const labelClasses = useLabelStyles();
    const isConfirmation = activeStep === steps.Confirmation;

    return (
        <div className={classes.root}>
            <Stepper className={classes.alternativeLabel} activeStep={isConfirmation ? activeStep + 1 : activeStep} connector={<StepConnector />} alternativeLabel>
                {Object.keys(steps).map(step => (
                    <Step key={step}>
                        <StepLabel classes={labelClasses} StepIconComponent={StepIcon}>
                            {step}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}