import React, { useState, useEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { Box, Divider, Grid, Typography, Breadcrumbs, makeStyles, Card, CardContent } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import api from '../helpers/api';

const useStyles = makeStyles(theme => ({
  status: {
    fontWeight: 600
  },
  card: {
    backgroundColor: theme.palette.grey[100],
  },
  total: {
    fontFamily: 'Helvetica',
  }
}));

export default function HomeDetail() {
  const classes = useStyles();
  const { params } = useRouteMatch();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [payment, setPayments] = useState(null);

  useEffect(() => {
    const fetchPayments = async () => {
      setFetching(true);
      try {
        const payments = await api(`/api/payments/${params.paymentId}`);
        setPayments(payments);
        setError('');
      } catch (error) {
        setError(error.message);
      } finally {
        setFetching(false);
      }
    };
    fetchPayments();
  }, [setPayments, params]);

  if (fetching || !payment) {
    return <Typography color="primary">Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Typography component={Link} color="primary" to="/payments">
          Payments
        </Typography>
        <Typography color="textPrimary">#{params.paymentId}</Typography>
      </Breadcrumbs>
      <Box mt={2} mb={4}>
        <Typography color="primary" variant="h4">{payment.hearing.caseName}</Typography>
        {payment.hearing.status === 'Cancelled' &&
          <Typography variant="body2" color="error">This hearing has been cancelled.</Typography>
        }
      </Box>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography gutterBottom color="textPrimary" variant="h6">Basic information</Typography>
            <Divider />
            <Box pt={3} pb={8} maxWidth={400}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">Status</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1" className={classes.status}>{new Date(payment.hearing.scheduledDate) < new Date() ? 'Past' : 'Upcoming'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">Case Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">#{payment.hearing.caseNumber}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">Job Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">#{payment.hearing.jobId}</Typography>
                </Grid>
              </Grid>
            </Box>
          <Typography gutterBottom color="textPrimary" variant="h6">Billing information</Typography>
            <Divider />
            <Box pt={3} pb={8} maxWidth={400}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">Email</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">{payment.account.emailAddress}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">{payment.account.displayName}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">Address</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="body1">{payment.account.firmAddressOne}</Typography>
                </Grid>
              </Grid>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography gutterBottom color="textPrimary" variant="h6">Order information</Typography>
            <Divider />
            <Card className={classes.card} square elevation={0}>
              <CardContent>
                <Box py={3}>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Typography color="textSecondary" variant="body2">Call-in Fee</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography color="textSecondary" variant="body2">${payment.amount || 0}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box pt={3} pb={1}>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Typography color="textPrimary" variant="h6" className={classes.total}>TOTAL</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textPrimary" variant="h6"  className={classes.total}>${payment.amount || 0}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
        </Grid>
      </Grid>
    </>
  );
}