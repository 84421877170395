import React from 'react';
import { differenceInHours } from "date-fns";
import { Link } from "react-router-dom";
import api from "./api";
import { Typography } from '@material-ui/core';

export const validateFullName = value => {
    // ensure fullName
    const [firstName, lastName] = value.split(' ');
    return !!(firstName && lastName) || 'Name is incorrectly formatted';
};

export const fullNameValidationOptions = {
    required: 'Please enter your full name',
    validate: validateFullName
};

export const emailValidationOptions = {
    required: 'Please enter an email address',
    maxLength: {
        value: 255,
        message: 'Email address must be 255 characters or less'
    },
    pattern: {
        value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        message: 'Invalid email format'
    }
};

export const passwordValidationOptions = {
    required: 'Please enter a password',
    minLength: {
        value: 6,
        message: 'Password must be at least 6 characters'
    }
};

export const passwordConfirmValidationOptions = (field) => ({
    required: 'Please enter a password',
    minLength: {
        value: 6,
        message: 'Password must be at least 6 characters'
    },
    validate: (value) => value === field || 'Passwords must match'
});

export const minDateValidationOptions = minDate => ({
    required: 'Please select a date',
    validate: value => differenceInHours(value, minDate) >= 24 || 'Requests must be made 24 hours in advance'
});

export const existingAccountValidation = (email) =>
    api('/api/accounts/checkExistingAccount', {
        query: {
            emailAddress: email
        }
    })
    .then(() => true)
    .catch(error => <span>{error.message} Please <Typography variant="body2" color="primary" component={Link} to="/signin">login</Typography> or <Typography variant="body2" color="primary" component={Link} to="/verifyEmail">finish account setup</Typography>.</span>);