import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    background: {
      default: '#ffffff',
      grey: '#f7f8fa'
    },
    primary: {
      main: '#2f4c75'
    },
    secondary: {
      main: '#087e17'
    },
    text: {
      primary: '#000000',
      secondary: '#585858'
    }
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: [
      'Helvetica',
      'Libre Baskerville',
      'sans-serif',
      '-apple-system',
      'Segoe UI',
      'Roboto',
      'Oxygen-Sans',
      'Ubuntu',
      'Cantarell',
      'Neue',
    ].join(','),
    button: {
      textTransform: 'none',
    },
    h1: {
      fontFamily: 'Libre Baskerville',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Libre Baskerville',
      fontSize: '1.75rem',
      fontWeight: 'normal',
      lineHeight: 1.6
    },
    h3: {
      fontFamily: 'Libre Baskerville',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'Libre Baskerville',
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'Libre Baskerville',
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: 'Libre Baskerville',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 11,
      fontWeight: 300
    },
    subtitle2: {
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    body2: {
      fontSize: '14px'
    }
  },
});