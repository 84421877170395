import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from '@stripe/react-stripe-js';
import Button from '../button';
import { PaymentSummaryBox } from './Payment';
import { Checkbox, StripeElements } from '../form';
import { NewFormControl as FormControl } from '../form/FormControl';
import ErrorList from '../form/ErrorList';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { renderTextField } from '../form/TextField';

export default function PaymentForm({
    currentAccount,
    paymentSecret,
    onPaymentComplete,
    hearingFee,
    onCancelClick,
    formSubmissionState
}) {
    const { handleSubmit, formState, getValues, ...form } = useForm({
        defaultValues: {
            name: ''
        }
    });
  const stripe = useStripe();
  const elements = useElements();
  const [saveCard, setSaveCard] = useState(false);
  const { isDirty, isSubmitting } = formState;
  const disabled = (!stripe || !elements) || !isDirty || isSubmitting;
  
  const onFormSubmit = async () => {
    if (disabled) {
        return;
    }

    const billing_details = {
        name: getValues('name')
    };
    let receipt_email = '';
    if (currentAccount) {
        billing_details.email = currentAccount.emailAddress;
        receipt_email = currentAccount.emailAddress;
    }

    const card = elements.getElement(CardNumberElement);
    const payload = await stripe.confirmCardPayment(paymentSecret, {
        payment_method: {
            card,
            billing_details: {
                name: getValues('name')
            }
        },
        receipt_email,
        save_payment_method: currentAccount && currentAccount.stripeId && saveCard
    });

    if (payload.error) {
        console.log('[error]', payload.error);
        return formSubmissionState.setError(payload.error);
    } else {
        if (payload.paymentIntent.status === 'succeeded') {
            return onPaymentComplete && onPaymentComplete(payload.paymentIntent);
        }
    }
  };

    return (
        <Box mt={4}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container spacing={4}>
                    <Grid item sm={7}>
                        <Box>
                            <Box mb={4}>
                                <Box mb={2}>
                                    <Typography variant="h6">Payment Information</Typography>
                                </Box>
                                <Divider />
                            </Box>
                            {renderTextField(form)({
                                label: 'Name on Card',
                                name: 'name',
                                placeholder: 'Cardholder Name',
                                autocomplete: 'cc-name',
                                validate: { required: 'Please enter the name on the card' },
                                withFormControl: true
                            })}
                            <FormControl label="Card Number" id="cardNumber">
                                <CardNumberElement
                                    id="cardNumber"
                                    placeholder="Your card Number"
                                    options={StripeElements.ELEMENT_OPTIONS}
                                />
                            </FormControl>
                            <FormControl label="Expiration Date" id="expiry">
                                <CardExpiryElement
                                    id="expiry"
                                    options={StripeElements.ELEMENT_OPTIONS}
                                />
                            </FormControl>
                            <FormControl label="CVC" id="cvc">
                                <CardCvcElement
                                    id="cvc"
                                    options={StripeElements.ELEMENT_OPTIONS}
                                />
                            </FormControl>
                            {(currentAccount && currentAccount.stripeId) && (
                                <Checkbox
                                    label="Save for future use"
                                    onChange={() => setSaveCard(!saveCard)}
                                />
                            )}
                            {onCancelClick && (
                                <Button variant="contained" color="secondary" onClick={onCancelClick}>Cancel</Button>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={5}>
                        <PaymentSummaryBox hearingFee={hearingFee} />
                        {formSubmissionState.error && <ErrorList errors={[formSubmissionState.error.message]} />}
                        <Button variant="contained" color="primary" type="submit" loading={isSubmitting} disabled={disabled} fullWidth>Submit Payment</Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
  );
}
