import React from 'react';
import {
    InputLabel,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    formControlContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: props => props.displayOnly ? 'baseline' : 'flex-end',
        flexDirection: 'row',
        maxWidth: '570px',
        marginBottom: props => props.gutterBottom ? '20px' : '0',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%'
        }
    },
    label: {
        color: '#585858',
        fontSize: '14px',
        width: '100%',
        marginBottom: '13px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '158px',
        }
    },
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '&.formControlRow': {
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px'
        }
    },
    error: {
        color: theme.palette.error.main,
        marginBottom: '4px'
    },
    input: {
        fontSize: '14px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        width: '100%'
    },
}));

export const NewFormControl = ({
    label,
    id,
    error,
    children,
    row = false,
    showLabel = true,
    displayOnly = false,
    gutterBottom = true,
}) => {
    const classes = useStyles({ displayOnly, gutterBottom });

    return (
        <div className={classes.formControlContainer}>
            {showLabel && <InputLabel id={id} className={classes.label}>{label}</InputLabel>}
            <div className={`${classes.formControl} ${row ? 'formControlRow' : ''}`}>
                {error && <span className={classes.error}>{error}</span>}
                {children}
            </div>
        </div>
    );
};